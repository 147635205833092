import React from "react";
import { notification } from "antd";
import Text from "antd/lib/typography/Text";


export const ApiErrorNotification = (error: any) => {
  // const history = useHistory();
  // const dispatch = useDispatch();

  let { message, errors } = error;
  if (message) {
    notification.open({
      type: "error",
      message: <Text>{message}</Text>,
      description:
        errors && Object.keys(errors).map((err) => <div>{errors[err]}</div>),
      // duration:300
    });
  }
  // let errorType = error.errorType;
};
