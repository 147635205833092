import React, { useContext, useEffect, useRef, useState } from "react";
import ChatMessage from "../chatMessage";

import {
  useQuery,
  useQueryClient,
  QueryClient,
  QueryClientProvider,
  useInfiniteQuery,
} from "react-query";
import { Dropdown, MenuProps, Skeleton, Spin } from "antd";
import {
  EditMessages,
  FetchMessages,
  SendMessages,
} from "../../services/chats";
import { AudioRecorder } from "react-audio-voice-recorder";
import { relative } from "path";
import { MessageContext, RoomContext } from "../../App";
import { LoadingOutlined } from "@ant-design/icons";

function ChatRoom({ roomId, user, setRoomId, group }: any) {
  const queryClient = useQueryClient();
  const [Scroll, setScroll] = useState(0);
  const [User, setUser] = useState<any>({});
  const [Loading, setLoading] = useState<any>(true);
  const newMessage = useContext<any>(MessageContext);
  const [Room, SetRoom] = useContext<any>(RoomContext);
  useEffect(() => {
    SetRoom(roomId);
  }, [roomId]);
  const [reply, setReply] = useState({
    id: 0,
    message: "",
  });
  const [Edit, setEdit] = useState({
    id: 0,
    message: "",
  });
  const [UserLoc, setUserLoc] = useState<any>({});
  useEffect(() => {
    const t = JSON.parse(window.localStorage.getItem("user") as string);
    setUserLoc(t);
  }, []);

  const sendFile = (files: any, type: any) => {
    for (var x = 0; x < files.length; x++) {
      let newMessages = MessagesSetting.data;
      newMessages.unshift({
        text: (
          <div style={{padding:"20px 40px"}}>
            <Spin
              indicator={
                <LoadingOutlined style={{ fontSize: 24, color: "#fff" }} spin />
              }
            />
          </div>
        ),
        is_mine: 1,
      });
      setMessagesSetting({ ...MessagesSetting, data: newMessages });
      var formData = new FormData();
      formData.append(
        `content[${type === "2" ? "voice" : ""}${type === "3" ? "audio" : ""}${
          type === "4" ? "image" : ""
        }${type === "2" ? "video" : ""}${type === "6" ? "doc" : ""}]`,
        files[x]
      );
      formData.append("chat_id", roomId);
      formData.append("type", type);
      if (reply.id !== 0)
        formData.append("reply_message_id", reply.id.toString());
      SendMessages(formData).then(() => {
        queryClient.invalidateQueries("Messages");
        queryClient.invalidateQueries("UsersChats");

        setReply({
          id: 0,
          message: "",
        });
        divForAutoScroll.current.scrollIntoView({ behavior: "smooth" });
      });
    }
  };

  const items: MenuProps["items"] = [
    {
      key: "1",
      label: (
        <>
          <label htmlFor="uploadImage">
            <img src="/images/image.svg" alt="" />
          </label>
          <input
            onChange={(e) => sendFile(e.target.files, "4")}
            id="uploadImage"
            type="file"
            accept="image/jpeg, image/png, image/jpg"
            multiple
          ></input>
        </>
      ),
    },
    {
      key: "2",
      label: (
        <>
          <label htmlFor="uploadVedio">
            <img
              style={{ transform: "scale(1.2)" }}
              src="/images/vedio.svg"
              alt=""
            />
          </label>
          <input
            onChange={(e) => sendFile(e.target.files, "5")}
            id="uploadVedio"
            type="file"
            accept="video/mp4,video/x-m4v,video/*"
            multiple
          ></input>
        </>
      ),
    },
    {
      key: "3",
      label: (
        <>
          <label htmlFor="uploadAudio">
            <img src="/images/audio.svg" alt="" />
          </label>
          <input
            onChange={(e) => sendFile(e.target.files, "3")}
            id="uploadAudio"
            type="file"
            accept="audio/mp3,audio/*;capture=microphone"
            multiple
          ></input>
        </>
      ),
    },
    {
      key: "4",
      label: (
        <>
          <label htmlFor="uploadFile">
            <img src="/images/file.svg" alt="" />
          </label>
          <input
            onChange={(e) => sendFile(e.target.files, "6")}
            id="uploadFile"
            type="file"
            multiple
          ></input>
        </>
      ),
    },
  ];

  const [MessagesSetting, setMessagesSetting] = useState<any>({
    page: 0,
    loadingMore: false,
    data: undefined,
  });

  useEffect(() => {
    setLoading(true);
  }, [group, roomId]);

  const { data, fetchNextPage, status, isLoading }: any = useInfiniteQuery(
    ["PrivateMessages", roomId, group],
    async ({ pageParam = 1 }) => FetchMessages(roomId, pageParam, group, ""),
    {
      keepPreviousData: true,
      getNextPageParam: (lastPage) => MessagesSetting.page + 1 ?? false,
      cacheTime: 0,
      staleTime: Infinity,
      refetchInterval: 10000,
      enabled: roomId !== undefined && roomId !== 0,
    }
  );
  const divForAutoScroll = useRef<any>();
  const [messages, setMessages] = useState([]);
  const [formValue, setFormValue] = useState("");

  useEffect(() => {
    if (MessagesSetting.page === 0 && divForAutoScroll.current)
      divForAutoScroll.current.scrollIntoView();
  }, [MessagesSetting.data]);
  useEffect(() => {
    setTimeout(() => {
      if (MessagesSetting.page === 0 && divForAutoScroll.current)
        divForAutoScroll.current.scrollIntoView();
    }, 500);
  }, [roomId]);

  const handleScroll = (e: any) => {
    let newArr = MessagesSetting;
    if (e.currentTarget.scrollTop === 0) {
      setScroll(e.currentTarget.scrollTop);
      if (!MessagesSetting.loadingMore) {
        if (newArr.page.toString === MessagesSetting.page.toString) {
          newArr.loadingMore = true;
          newArr.page = MessagesSetting.page + 1;
          setMessagesSetting({ ...newArr });
          fetchNextPage();
        }
      }
    }
  };

  useEffect(() => {
    if (status === "success") {
      let allProducts: any = [];

      data?.pages &&
        data?.pages[0] !== undefined &&
        data?.pages?.map((page: any) => {
          if (page !== null) allProducts = [...allProducts, ...page.json];
        });
      let newArr = MessagesSetting;
      newArr.data = allProducts;

      if (data?.pages[data?.pages.length - 1]?.json.length > 0) {
        newArr.loadingMore = false;
      } else {
        newArr.loadingMore = true;
      }
      setMessagesSetting({ ...newArr });
      setLoading(false);
    }
  }, [data?.pages]);

  const AlwaysScrollToBottom = () => {
    return <div ref={divForAutoScroll} />;
  };
  useEffect(() => {
    const t = JSON.parse(window.localStorage.getItem("user") as string);
    setUser(t);
  }, []);
  const sendMessage = async (e: any) => {
    divForAutoScroll.current.scrollIntoView({ behavior: "smooth" });
    e.preventDefault();
    let newMessages = MessagesSetting.data;
    newMessages.unshift({ text: formValue, is_mine: 1, is_seen: "pending" });
    setMessagesSetting({ ...MessagesSetting, data: newMessages });
    var formData = new FormData();
    formData.append("text", formValue);
    formData.append("chat_id", roomId);
    formData.append("user_id", User?.id);
    formData.append("type", "1");
    if (reply.id !== 0)
      formData.append("reply_message_id", reply.id.toString());

    SendMessages(formData).then((res) => {
      // queryClient.invalidateQueries("Messages");
      // queryClient.invalidateQueries("UsersChats");
      let newMessages = MessagesSetting.data;
      newMessages[newMessages.findIndex((x: any) => x.text == formValue)] = {
        ...newMessages[newMessages.findIndex((x: any) => x.text == formValue)],
        is_seen:
          newMessages[
            newMessages.findIndex((x: any) => x.text == formValue)
          ] === 1
            ? 1
            : 0,
      };
      // console.log("newMessagesnewMessages", newMessages);
      setMessagesSetting({ ...MessagesSetting, data: newMessages });
      setReply({
        id: 0,
        message: "",
      });
      divForAutoScroll.current.scrollIntoView({ behavior: "smooth" });
    });

    setFormValue("");
  };

  const addAudioElement = (blob: any) => {
    let newMessages = MessagesSetting.data;
    newMessages.unshift({
      text: (
        <div style={{padding:"20px 40px"}}>
          <Spin
            indicator={
              <LoadingOutlined style={{ fontSize: 24, color: "#fff" }} spin />
            }
          />
        </div>
      ),
      is_mine: 1,
    });
    setMessagesSetting({ ...MessagesSetting, data: newMessages });
    console.log(blob);
    const url = URL.createObjectURL(blob);
    var formData = new FormData();
    formData.append(`content[voice]`, blob);
    formData.append("chat_id", roomId);
    formData.append("type", "2");
    if (reply.id !== 0)
      formData.append("reply_message_id", reply.id.toString());
    SendMessages(formData).then(() => {
      queryClient.invalidateQueries("Messages");
      queryClient.invalidateQueries("UsersChats");

      setReply({
        id: 0,
        message: "",
      });
      divForAutoScroll.current.scrollIntoView({ behavior: "smooth" });
    });
  };
  const editMessage = (e: any) => {
    divForAutoScroll.current.scrollIntoView({ behavior: "smooth" });
    e.preventDefault();
    var formData = new FormData();
    formData.append("text", formValue);
    formData.append("id", Edit.id.toString());
    setEdit({
      id: 0,
      message: "",
    });
    EditMessages(formData).then(() => {
      queryClient.invalidateQueries("Messages");
      queryClient.invalidateQueries("UsersChats");

      divForAutoScroll.current.scrollIntoView({ behavior: "smooth" });
    });

    setFormValue("");
  };
  useEffect(() => {
    console.log("newMessage", newMessage);
    if (
      newMessage?.text &&
      roomId === newMessage?.chat_id &&
      newMessage?.is_mine === 0
    ) {
      divForAutoScroll?.current?.scrollIntoView({ behavior: "smooth" });
      let newMessages = MessagesSetting.data;
      newMessages?.unshift({
        text: newMessage?.text,
        is_mine: 0,
        user: newMessage?.user,
      });
      setMessagesSetting({ ...MessagesSetting, data: newMessages });

      setTimeout(() => {
        divForAutoScroll?.current?.scrollIntoView({ behavior: "smooth" });
      }, 300);
    }
    if (
      newMessage?.text &&
      roomId === newMessage?.chat_id &&
      newMessage?.is_mine === 1
    ) {
      divForAutoScroll?.current?.scrollIntoView({ behavior: "smooth" });
      let newMessages = MessagesSetting.data;
      newMessages[newMessages.findIndex((x: any) => x.id == newMessage.id)] = {
        ...newMessages[
          newMessages.findIndex((x: any) => x.id == newMessage.id)
        ],
        is_seen: newMessage.is_seen,
      };
      // console.log("newMessagesnewMessages", newMessages);
      setMessagesSetting({ ...MessagesSetting, data: newMessages });

      setTimeout(() => {
        divForAutoScroll?.current?.scrollIntoView({ behavior: "smooth" });
      }, 300);
    }
  }, [newMessage]);
  return (
    <div className="chatBoxCon">
      {Loading ? (
        <>
          <Skeleton paragraph={{ rows: 5 }} active />
        </>
      ) : (
        <>
          <div
            onScroll={handleScroll}
            style={{
              height:
                (group &&
                  user?.props?.find((o: any) => o.perm === 1 || o.perm === 3)
                    ?.allow === 1) ||
                (!group &&
                  (user?.members
                    ?.find((o: any) => o.id === UserLoc.id)
                    .props?.find((o: any) => o.perm === 1)?.allow === 0 ||
                    user?.members
                      ?.find((o: any) => o.id === UserLoc.id)
                      .props?.find((o: any) => o.perm === 1) === undefined))
                  ? "67vh"
                  : "60vh",
            }}
            className="chatBox"
          >
            {/* <button
      className="downArrow"
        onClick={() =>
          divForAutoScroll.current.scrollIntoView({ behavior: "smooth" })
        }
      >
      </button> */}
            {MessagesSetting.data &&
              [...MessagesSetting.data]
                .reverse()
                .map((msg: any, index: number) => (
                  <ChatMessage
                    user={user}
                    group={group}
                    roomId={roomId}
                    key={index}
                    message={msg}
                    setRoomId={(Id: any) => setRoomId(Id)}
                    setReply={(rep: any) => setReply(rep)}
                    setEdit={(edit: any) => {
                      setEdit(edit);
                      setFormValue(edit.message);
                    }}
                  />
                ))}
            <AlwaysScrollToBottom />
            {/* <span ref={divForAutoScroll}></span> */}
          </div>
          {(group &&
            user?.props?.find((o: any) => o.perm === 1 || o.perm === 3)
              ?.allow === 1) ||
          (!group &&
            (user?.members
              ?.find((o: any) => o.id === UserLoc.id)
              .props?.find((o: any) => o.perm === 1)?.allow === 0 ||
              user?.members
                ?.find((o: any) => o.id === UserLoc.id)
                .props?.find((o: any) => o.perm === 1) === undefined)) ? (
            <>
              {user.id && (
                <div className="sendMessageNotAlowed">
                  You don't have permission to send messages
                </div>
              )}
            </>
          ) : (
            <form
              style={{ position: "relative" }}
              onSubmit={(e) =>
                Edit.id === 0 ? sendMessage(e) : editMessage(e)
              }
            >
              {Edit.id !== 0 && (
                <div className="replyBoxForm">
                  <div
                    onClick={() => {
                      setEdit({
                        id: 0,
                        message: "",
                      });
                      setFormValue("");
                    }}
                    className="closeBtn"
                  >
                    <img src="/images/close.svg" alt="" />
                  </div>
                  Edit
                </div>
              )}
              {reply.id !== 0 && (
                <div className="replyBoxForm">
                  <div
                    onClick={() => {
                      setReply({
                        id: 0,
                        message: "",
                      });
                      setFormValue("");
                    }}
                    className="closeBtn"
                  >
                    <img src="/images/close.svg" alt="" />
                  </div>
                  Replies to {reply.message}
                </div>
              )}
              <div className="inputCon">
                {formValue.length === 0 ? (
                  // <button
                  //   onClick={sendMessage}
                  //   type="submit"
                  //   className="button-submit"
                  //   disabled={!formValue}
                  // >
                  //   <img src="/images/Micraphone.svg" alt="" />
                  // </button>
                  <AudioRecorder
                    downloadOnSavePress={false}
                    onRecordingComplete={addAudioElement}
                    audioTrackConstraints={{
                      noiseSuppression: true,
                      echoCancellation: true,
                    }}
                    downloadFileExtension="webm"
                    classes={{ AudioRecorderClass: "button-submit" }}
                  />
                ) : (
                  <button
                    onClick={(e) =>
                      Edit.id === 0 ? sendMessage(e) : editMessage(e)
                    }
                    type="submit"
                    className="button-submit"
                    disabled={!formValue}
                  >
                    <img src="/images/send.svg" alt="" />
                  </button>
                )}

                <input
                  value={formValue}
                  onChange={(e) => setFormValue(e.target.value)}
                  placeholder="Write your words ..."
                />

                <Dropdown
                  overlayClassName="filesDropDown"
                  menu={{ items }}
                  placement="top"
                >
                  <button
                    type="button"
                    onClick={(e) => e.preventDefault()}
                    className="create-submit"
                  >
                    <img src="/images/clip.png" alt="" />
                  </button>
                </Dropdown>
              </div>
            </form>
          )}
        </>
      )}
    </div>
  );
}

export default ChatRoom;
