import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Upload,
  notification,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import React, { useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { AddFile } from "../../services/contacts";
import { DeleteSpace, PostSpace } from "../../services/spaces";
import AddSpace from "../addSpace";
import { CloseOutlined } from "@ant-design/icons";

const { Option } = Select;

const ManageSpaces = ({ Spaces, Groups, onFinish }: any) => {
  const [Loading, setLoading] = useState(false);
 
  const OpenAddSpace = () => {
    const createModal = Modal.info({
      className: "adduserModal",
      title: "Create new space",
      width: "40%",
      autoFocusButton:null,
      centered: true,
      content: <AddSpace Groups={Groups} onFinish={() => onFinish()} />,
      onOk() {},
      okText: "Cancel",
      closeIcon: (
        <>
          <CloseOutlined color="#fff" />
        </>
      ),
      maskClosable: true,
      closable: true,
    });
  };
  const OpenEditSpace = (space: any) => {
    const createModal = Modal.info({
      className: "adduserModal",
      autoFocusButton:null,
      title: (
        <div
          style={{
            display: "flex",
            alignContent: "center",
            justifyContent: "space-between",
          }}
        >
          {"Edit " + space.name + " space "}
          <Button
            onClick={() => DeleteSpaceHandle(space)}
            loading={Loading}
            style={{
              border: "1px solid #FF453A33",
              borderRadius: 11,
              color: "#FF453A",
              background:
                " linear-gradient(0deg, rgba(255, 69, 58, 0.2), rgba(255, 69, 58, 0.2)),linear-gradient(0deg, rgba(255, 69, 58, 0.1), rgba(255, 69, 58, 0.1))",
            }}
          >
            Delete Space
          </Button>
        </div>
      ),
      width: "40%",
      centered: true,
      content: (
        <AddSpace space={space} Groups={Groups} onFinish={() => onFinish()} />
      ),
      onOk() {},
      okText: "Cancel",
      closeIcon: (
        <>
          <CloseOutlined color="#fff" />
        </>
      ),
      maskClosable: true,
      closable: true,
    });
  };
  const DeleteSpaceHandle = (space: any) => {
    setLoading(true);
    DeleteSpace(space.id)
      .then((res: any) => {
        notification.open({
          type: "success",
          message: "Space " + space.name + " Deleted successfully",
        });
        Modal.destroyAll();
      })
      .catch(() => {
        debugger;
        setLoading(false);
      });
  };
  return (
    <div className="userCreateForm">
      <div className="chatUsers">
        {Spaces?.json.map((space: any) => (
          <div className={"userCard"}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div className="cardImgCon">
                <div className="imgPlaceHolder">
                  <img src={space.icon.url} alt="" />
                </div>
              </div>
              <div className="cardText">
                <div className="cardTitle">{space.name}</div>
              </div>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Button
                onClick={(e) => OpenEditSpace(space)}
                className="editButton"
              >
                <img src="/images/editIconLight.svg" alt="" />
                Edit Space
              </Button>
            </div>
          </div>
        ))}
        <Button
          onClick={() => OpenAddSpace()}
          className="ConfirmButton"
          type="primary"
        >
          Create new space
        </Button>
      </div>
    </div>
  );
};

export default ManageSpaces;
