import { Avatar, Input, List, Modal, Skeleton, notification } from "antd";
import React, { useEffect, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import AddGroup from "../addGroup";
import { AddChat, FetchAllContacts, FetchContacts } from "../../services/contacts";
import { FetchSpaces } from "../../services/spaces";
import { CloseOutlined } from "@ant-design/icons";
import ChatUsersForward from "../chatUsersForward";
import AddContactModal from "../addContact";

const { Search } = Input;

const ChatUsers = ({
  setRoomId,
  setUser,
  data,
  RoomId,
  setSubRoomId,
  forward,
  loading,
  setSearch,
  groups,
  refetch
}: any) => {
  const queryClient = useQueryClient();
  const [UserLoc, setUserLoc] = useState<any>({});
  const [NewContact, setNewContact] = useState<any>(0);
  
  useEffect(() => {
    if (NewContact !== 0) {
      AddChat({user_id:NewContact}).then((res: any) => {
        notification.open({
          type: "success",
          message: "user added successfully",
        });
        Modal.destroyAll();
        refetch();
        setUser(res?.json);
        setRoomId(res?.json?.id)
      })
    }
  }, [NewContact]);

  useEffect(() => {
    const t = JSON.parse(window.localStorage.getItem("user") as string);
    setUserLoc(t);
  }, []);
  const Contacts = useQuery(["Contacts"], async () => FetchContacts(0), {
    cacheTime: 0,
    staleTime: Infinity,
  });
  const AllContacts = useQuery(
    ["AllContacts"],
    async () => FetchAllContacts(0),
    {
      cacheTime: 0,
      staleTime: Infinity,
    }
  );
  console.log("AllContacts", AllContacts);
  const Spaces = useQuery("Spaces", async () => FetchSpaces(), {
    cacheTime: 0,
    staleTime: Infinity,
  });
  const CreateGroupModal = () => {
    const createModal = Modal.info({
      className: "adduserModal",
      title: "Create new group",
      width: "40%",
      centered: false,
      content: <AddGroup Contacts={Contacts} Spaces={Spaces} />,
      onOk() {},
      okText: "Cancel",
      autoFocusButton: null,
      closeIcon: (
        <>
          <CloseOutlined color="#fff" />
        </>
      ),
      maskClosable: true,
      closable: true,
      afterClose() {
        queryClient.invalidateQueries("groups");
      },
    });
  };
  const AddContact = () => {
    Modal.info({
      className: "forwordModal",
      centered: true,
      title: "Add Contact",
      autoFocusButton: null,
      icon: <></>,
      content: (
        <div>
          <AddContactModal
            forward
            data={AllContacts?.data?.json}
            RoomId={0}
            setUser={(data: any) => setNewContact(data)}
            setSearch={(data: any) => {
              setSearch(data);
            }}
          />
        </div>
      ),
      onOk() {},
      okText: "Cancel",
      closeIcon: (
        <>
          <CloseOutlined color="#fff" />
        </>
      ),
      maskClosable: true,
      closable: true,
    });
  };
  return (
    <div className="chatUsers">
      <Search
        placeholder="Seach"
        onSearch={(e: any) => setSearch(e)}
        onChange={(e) => e.target.value === "" && setSearch("")}
      />
      {loading ? (
        <div style={{ padding: " 30px 30px" }}>
          <Skeleton style={{ marginBottom: 20 }} active avatar />
          <Skeleton style={{ marginBottom: 20 }} active avatar />
          <Skeleton style={{ marginBottom: 20 }} active avatar />
          <Skeleton style={{ marginBottom: 20 }} active avatar />
          <Skeleton style={{ marginBottom: 20 }} active avatar />
        </div>
      ) : (
        <>
          {data &&
            data.map((user: any, index: number) => (
              <div
                onClick={() => {
                  if (!forward) setRoomId(user.id);
                  setUser(user);
                  if (!forward) {
                    if (user?.staff_chat !== undefined)
                      setSubRoomId(user?.staff_chat?.id);
                    else setSubRoomId(0);
                  }
                }}
                className={RoomId === user.id ? "userCard active" : "userCard"}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div className="cardImgCon">
                    <div className="imgPlaceHolder">
                      <img
                        src={
                          !groups
                            ? user?.members.find(
                                (item: any) => item.id !== UserLoc.id
                              )?.profile_image?.url
                            : user?.icon?.url
                        }
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="cardText">
                    <div className="cardTitle">{user?.name}</div>
                    <div className="cardDes">
                      {!forward && user?.last_message?.content}
                    </div>
                  </div>
                </div>
                <div className="cardDetails">
                  <div className="cardTime">
                    {!forward && user?.last_message?.created_at?.split("T")[0]}
                  </div>
                  {!forward && user?.unread_messages_count !== 0 && (
                    <div className="messageCount">
                      {user?.unread_messages_count}
                    </div>
                  )}
                </div>
              </div>
            ))}
        </>
      )}
      {groups &&    (UserLoc?.role === "admin" || UserLoc?.role === "owner" || UserLoc?.role === "sp")&&(
        <button onClick={() => CreateGroupModal()} className="addGroupBtn">
          <img src="/images/profile-2user.png" alt="" />
        </button>
      )}
      {!groups && (
        <button onClick={() => AddContact()} className="addGroupBtn">
          <img style={{ width: "20px" }} src="/images/add-icon.png" alt="" />
        </button>
      )}
    </div>
  );
};

export default ChatUsers;
