import ApiResult from "./api-result"
import { ApiError } from "./api-error"

type ApiResponse<T> = ApiResult<T> | ApiError

// Type Guards..
export function isError(result: ApiResponse<any>): result is ApiError {
  return (result as ApiError).errorType !== undefined
}

export default ApiResponse