import React, { useState } from "react";
import { FetchContacts } from "../../../services/contacts";
import { useQuery } from "react-query";
import { Button, Checkbox, Modal, Radio } from "antd";
import PermissionsList from "../permissionsList";
import { CloseOutlined } from "@ant-design/icons";

const MyContactList = ({ data, checkedUsers, currentUser, refetch }: any) => {
  const [Loading, setLoading] = useState(false);
  const [CheckList, setCheckList] = useState(
    checkedUsers.map(function (obj: any) {
      return obj?.contact.username;
    })
  );
  const [CanEdit, setCanEdit] = useState(0);
  const OpenPermissionPopUp = (user: any) => {
    if (CanEdit === 0) {
      console.log(currentUser);
      console.log(user);
      console.log(checkedUsers);
      FetchContacts(user?.contact?.id)
        .then((res) => {
          setCanEdit(user?.contact?.username);
          let otherUser = checkedUsers?.find(
            (o: any) => o.contact.username === user.contact.username
          )
            ? checkedUsers?.find(
                (o: any) => o.contact.username === user.contact.username
              )
            : user;
          console.log("json", res.json);
          let currentUserNew = res.json?.find(
            (o: any) => o.contact.username === currentUser.contact.username
          )
            ? res.json?.find(
                (o: any) => o.contact.username === currentUser.contact.username
              )
            : currentUser;
          Modal.info({
            className: "MyContactList",
            autoFocusButton:null,
            title: (
              <div className="modalheader">
                <div className="modalTitle">Setting up Permissions</div>
                <div className="modalSubtitle">
                  Set the Permissions for current and new user, it will limits
                  chat features with each other
                </div>
              </div>
            ),
            width: "40%",
            content: (
              <PermissionsList
                refetch={() => refetch()}
                currentUser={currentUserNew}
                otherUser={otherUser}
              />
            ),
            centered: false,
            onOk() {},
            okText: "Cancel",
            closeIcon: (
              <>
                <CloseOutlined color="#fff" />
              </>
            ),
            maskClosable: true,
            closable: true,
          });

          setCanEdit(0);
        })
        .catch(() => {
          setCanEdit(0);
        });
    }
  };

  return (
    <>
      <Checkbox.Group
        onChange={(e: any) => setCheckList(e)}
        defaultValue={CheckList}
      >
        <div className="chatUsers">
          {data?.json.map((user: any) => (
            <div className={"userCard"}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <div className="cardImgCon">
                  <div className="imgPlaceHolder">
                    <img src={user?.contact?.profile_image?.url} alt="" />
                  </div>
                </div>
                <div className="cardText">
                  <div className="cardTitle">{user?.contact?.full_name}</div>
                </div>
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                {CheckList.includes(user.contact?.username) && (
                  <Button
                    onClick={(e) => OpenPermissionPopUp(user)}
                    className="editButton"
                    loading={CanEdit === user?.contact?.username}
                  >
                    <img src="/images/editIconLight.svg" alt="" />
                    Edit
                  </Button>
                )}
                <Checkbox value={user?.contact?.username} />
              </div>
            </div>
          ))}
        </div>
      </Checkbox.Group>
      <Button
        onClick={() => Modal.destroyAll()}
        className="ConfirmButton"
        loading={Loading}
        type="primary"
      >
        Confirm
      </Button>
    </>
  );
};

export default MyContactList;
