import React from "react";
import { useQuery } from "react-query";
import { FetchFiles } from "../../../services/chats";
import { Col, Row } from "antd";

const Files = ({ RoomId,isModalOpen  }: any) => {
  const { data, status, isLoading } = useQuery(
    ["Files",RoomId,isModalOpen ],
    async () => FetchFiles(RoomId),{
      enabled:isModalOpen
    }
 
  );
  return (
    <div className="files">
      <Row gutter={[0, 15]}>
        {data?.json?.data?.map((file: any) => (
          <Col span={24}>
            <div className="fileCard">
              <div style={{ display: "flex" }}>
                <img className="fileIcon" src={"/images/file.svg"} alt="" />
                <div className="fileName">{file.file_name}</div>
              </div>
              <a
                href={file.url}
                download
                target="_blank"
              >
                <img
                  className="downFileIcon"
                  src={"/images/download.svg"}
                  alt=""
                />
              </a>
            </div>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default Files;
