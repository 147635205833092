import { Col, Input, Modal, Row } from "antd";
import React, { useEffect, useState } from "react";
import ChatUsers from "../../chatUsers";
import { FetchMessages, FetchUsersMessages } from "../../../services/chats";
import { useInfiniteQuery, useQuery } from "react-query";
import ChatRoom from "../../chatRoom";

const { Search } = Input;

const Private = () => {
  const [RoomId, setRoomId] = useState(0);
  const [user, setUser] = useState<any>({});
  const [UserLoading, setUsersLoading] = useState<any>(true);
  const [SearchTest, setSearch] = useState<any>("");
  const [SearchMessage, setSearchMessage] = useState<any>("");
  const [ModalSearch, setModalSearch] = useState(false);
  const { data, status, isLoading ,refetch} = useQuery(
    ["UsersChats", SearchTest],
    async () => FetchUsersMessages(1, SearchTest),
    {
      cacheTime: 0,
      staleTime: Infinity,
    }
  );
  console.log("data.data",data)
  useEffect(() => {
    if (
      data &&
      data?.json &&
      data?.json.length > 0 &&
      Object.keys(user).length === 0
    ) {
      setRoomId(data?.json[0].id);
      setUser(data?.json[0]);
      setUsersLoading(false);
    }
  }, [data?.json]);
  useEffect(() => {
    if (SearchTest !== "") setUsersLoading(true);
  }, [SearchTest]);
  const searchMessage: any = useInfiniteQuery(
    ["MessagesSearch", SearchMessage, RoomId],
    async ({ pageParam = 1 }) =>
      FetchMessages(RoomId, pageParam, 0, SearchMessage),
    {
      keepPreviousData: true,
      getNextPageParam: (lastPage) => false,
      cacheTime: 0,
      staleTime: Infinity,
      refetchInterval: 10000,
      enabled: RoomId !==undefined&&RoomId !== 0 && SearchMessage !== "",
    }
  );
 
  return (
    <div style={{ paddingTop: "7vh" }} className="chat">
      <Row gutter={[25, 0]}>
        <Col span={8}>
          <ChatUsers
            key={JSON.stringify(data?.json)}
            loading={UserLoading}
            data={data?.json}
            RoomId={RoomId}
            setRoomId={(Id: any) => {
              setRoomId(Id);
            }}
            setUser={(data: any) => {
              setUser(data);
            }}
            setSearch={(data: any) => {
              setSearch(data);
            }}
            refetch={()=>{
              refetch().then(()=>{
                return
              })
            }}
          />
        </Col>
        <Col span={16}>
          <button onClick={() => setModalSearch(true)} className="infoBtn">
            <img src="/images/magnifying-glass.png" alt="" />
          </button>
          <Modal
            className="groupModal"
            open={ModalSearch}
            onCancel={() => setModalSearch(false)}
            footer={[]}
          >
            <Search
              placeholder={"Seach for message in " + user.name + " Chat"}
              onSearch={(e: any) => setSearchMessage(e)}
              onChange={(e: any) =>
                e.target.value === "" && setSearchMessage("")
              }
            />
            <div className="searchMessageCon">
              {searchMessage?.data?.pages[0]?.json.length === 0 && (
                <div className="notFound">No Result found</div>
              )}
              {searchMessage?.data?.pages[0]?.json.map((searchMsg: any) => (
                <div className="searchMessage">
                  <div className="searchUser">
                    <img src={searchMsg.user?.profile_image?.url} alt="" />
                    {searchMsg?.user?.full_name}
                  </div>
                  <div className="searchMessageText">{searchMsg?.text}</div>
                  <div className="messageDate">
                    {searchMsg?.created_at.split("T")[0]}
                  </div>
                </div>
              ))}
            </div>
          </Modal>
          <div className="prviateUserName">{user?.name}</div>
          <ChatRoom
            user={user}
            roomId={RoomId}
            setRoomId={(Id: any) => setRoomId(Id)}
          />
        </Col>
      </Row>
    </div>
  );
};

export default Private;
