import React, { createContext, useEffect, useState } from "react";
import logo from "./logo.svg";
import "./styles/main.scss";

import { QueryClient, QueryClientProvider } from "react-query";
import ChatUsers from "./components/chatUsers";
import Login from "./components/login";
import Header from "./components/header";

import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import Layout from "./components/layout";

import Pusher from "pusher-js";
import { notification } from "antd";
import Text from "antd/lib/typography/Text";

export const MessageContext = createContext(null);
export const RoomContext = createContext<any>(null);

function App() {
  const queryClient = new QueryClient();

  const [UserLoc, setUserLoc] = useState<any>({});
  const [NewMessage, SetNewMessage] = useState<any>();
  const [Room, SetRoom] = useState<any>(0);
  useEffect(() => {
    const t = JSON.parse(window.localStorage.getItem("user") as string);
    setUserLoc(t);
  }, []);
  useEffect(() => {
    console.log("RoomRoom", Room);
  }, [Room]);
  useEffect(() => {
    const pako = require("pako");
    const atob = require("atob");
    if (UserLoc?.id) {
      const pusher = new Pusher("3c66a6b21dab57125df0", {
        cluster: "ap1",
        channelAuthorization: {
          endpoint: "https://cascade.wealthcoders.ae/api/broadcasting/auth",
          transport: "ajax",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        },
      });
      Pusher.log = (msg) => {
        console.log("data log", msg);
      };
      const channel = pusher.subscribe(`private-channel-user-${UserLoc.id}`);
      channel.bind("sync", function (data: any) {
        var b64Data = data.payload;
        var strData = atob(b64Data);
        var charData = strData.split("").map(function (x: any) {
          return x.charCodeAt(0);
        });
        var binData = new Uint8Array(charData);
        var data = pako.inflate(binData);
        var strData: any = String.fromCharCode.apply(
          null,
          new Uint16Array(data) as any
        );
        var newObject = JSON.parse(strData);
        console.log("newObject", newObject);
        if (newObject?.data?.chat_id === Room) {
          var wasTriggered = channel.trigger("client-sync", {
            hash: [newObject?.hash.toString()],
          });
        } else {
          var wasTriggered = channel.trigger("client-sync", {
            hash: [newObject?.hash.toString()],
            seen: true,
          });
        }
        SetNewMessage(newObject?.data);
        queryClient.invalidateQueries("groupsData");
        if (
          newObject?.data?.text &&
          newObject?.data?.is_mine === 0 &&
          newObject?.data?.chat_id === Room
        )
          notification.open({
            className: "messageNotification",
            message: newObject?.data?.user?.full_name,
            description: newObject?.data?.text,
            icon: (
              <img
                style={{
                  width: 50,
                  height: 50,
                  objectFit: "cover",
                  borderRadius: "50%",
                  marginInlineEnd: 10,
                }}
                src={newObject?.data?.user?.profile_image.url}
              />
            ),
          });
      });
    }
  }, [UserLoc]);

  return (
    <QueryClientProvider client={queryClient}>
      <MessageContext.Provider value={NewMessage}>
        <RoomContext.Provider value={[Room, SetRoom]}>
          <Router>
            <Switch>
              <Route
                path="/login"
                render={(props: any) => <Login {...props} />}
              />
              <Route path="/" render={(props: any) => <Layout {...props} />} />
            </Switch>
          </Router>
        </RoomContext.Provider>
      </MessageContext.Provider>
    </QueryClientProvider>
  );
}

export default App;
