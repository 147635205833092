import React, { Fragment, useEffect } from "react";
import Header from "../header";
import Login from "../login";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
} from "react-router-dom";
import { Col, Row } from "antd";
import Groups from "../chatPages/groups";
import Private from "../chatPages/private";

const Layout = () => {
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      history.push("/login");
    }
  }, []);
  return (
    <div className="layout">
      <Header />
      <div className="content">
        <Row justify={"center"}>
          <Col span={20}>
            <div className="contentBox">
              <Switch location={location}>
                <Route
                  key={"groups"}
                  path={"/groups"}
                  render={(props: any) => <Groups {...props} />}
                />
                <Route
                  key={"private"}
                  path={"/private"}
                  render={(props: any) => <Private {...props} />}
                />
                <Redirect from="/" to="/groups" />
              </Switch>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Layout;
