import { AxiosRequestConfig } from "axios";
import ApiService from "../../utils/api/api-service";
import ApiResponse, { isError } from "../../utils/api/models/api-response";
import { ApiErrorNotification } from "../../utils/ui/notificationService";
import { useQueryClient } from "react-query";

class ContactsService extends ApiService {
  constructor(config?: AxiosRequestConfig) {
    super({
      baseURL: "https://cascade.wealthcoders.ae/api/",
      ...config,
    });
  }

  public async Fetch(user_id:any): Promise<ApiResponse<any>> {
    return this.get(`/contact/all?${user_id!==0?"user_id="+user_id:"is_mine=0"}`);
  }
  public async FetchAll(user_id:any): Promise<ApiResponse<any>> {
    return this.get(`/contact/all?${user_id!==0?"user_id="+user_id:"is_mine=1"}`);
  }
  public async add(data:any): Promise<ApiResponse<any>> {
    return this.post(`/user/store`,data);
  }
  public async addChat(data:any): Promise<ApiResponse<any>> {
    return this.post(`/chat/store`,data);
  }
  public async addPermission(data:any): Promise<ApiResponse<any>> {
    return this.post(`/contact/store`,data);
  }
  public async addFile(data:any): Promise<ApiResponse<any>> {
    return this.post(`/file`,data);
  }

}

const contactsService = new ContactsService();

export const FetchContacts = async (user_id:any) => {
  const result: any = await contactsService.Fetch(user_id);
  if (isError(result)) {
    ApiErrorNotification(result);
    if (result.errorType === "Unauthorized") {
      localStorage.removeItem("token");
      window.location.href = "/login";
    }
    throw result;
  } else {
    return result;
  }
};
export const FetchAllContacts = async (user_id:any) => {
  const result: any = await contactsService.FetchAll(user_id);
  if (isError(result)) {
    ApiErrorNotification(result);
    if (result.errorType === "Unauthorized") {
      localStorage.removeItem("token");
      window.location.href = "/login";
    }
    throw result;
  } else {
    return result;
  }
};

export const AddContact = async (data:any) => {
  const result: any = await contactsService.add(data);
  if (isError(result)) {
    ApiErrorNotification(result);
    if (result.errorType === "Unauthorized") {
      localStorage.removeItem("token");
      window.location.href = "/login";
    }
    throw result;
  } else {
    return result;
  }
};
export const AddContactPermission = async (data:any) => {
  const result: any = await contactsService.addPermission(data);
  if (isError(result)) {
    ApiErrorNotification(result);
    if (result.errorType === "Unauthorized") {
      localStorage.removeItem("token");
      window.location.href = "/login";
    }
    throw result;
  } else {
    return result;
  }
};
export const AddFile = async (data:any) => {
  const result: any = await contactsService.addFile(data);
  if (isError(result)) {
    ApiErrorNotification(result);
    if (result.errorType === "Unauthorized") {
      localStorage.removeItem("token");
      window.location.href = "/login";
    }
    throw result;
  } else {
    return result;
  }
};
export const AddChat = async (data:any) => {
  const result: any = await contactsService.addChat(data);
  if (isError(result)) {
    ApiErrorNotification(result);
    if (result.errorType === "Unauthorized") {
      localStorage.removeItem("token");
      window.location.href = "/login";
    }
    throw result;
  } else {
    return result;
  }
};
