import { AxiosRequestConfig } from "axios";
import ApiService from "../../utils/api/api-service";
import ApiResponse, { isError } from "../../utils/api/models/api-response";
import { ApiErrorNotification } from "../../utils/ui/notificationService";
import { useQueryClient } from "react-query";

class SpaceService extends ApiService {
  constructor(config?: AxiosRequestConfig) {
    super({
      baseURL: "https://cascade.wealthcoders.ae/api/",
      ...config,
    });
  }

  public async Fetch(): Promise<ApiResponse<any>> {
    return this.get(`/space/all`);
  }
  async add(data:any): Promise<ApiResponse<any>> {
    return this.post(`/space/store`,data);
  }
  async edit(data:any): Promise<ApiResponse<any>> {
    return this.post(`/space/update`,data);
  }
  async Delete(id:any): Promise<ApiResponse<any>> {
    return this.delete(`/space/delete?id=${id}&force=1`);
  }
}

const spaceService = new SpaceService();

export const FetchSpaces = async () => {
  const result: any = await spaceService.Fetch();
  if (isError(result)) {
    ApiErrorNotification(result);
    if (result.errorType === "Unauthorized") {
      localStorage.removeItem("token");
      window.location.href = "/login";
    }
    throw result;
  } else {
    return result;
  }
};


export const PostSpace = async (data:any) => {
  debugger
  const result = await spaceService.add(data);
  if (isError(result)) {
    ApiErrorNotification(result);
    if (result.errorType === "Unauthorized") {
      localStorage.removeItem("token");
      window.location.href = "/login";
    }
    throw result;
  } else {
    return result;
  }
};
export const EditSpace = async (data:any) => {
  debugger
  const result = await spaceService.edit(data);
  if (isError(result)) {
    ApiErrorNotification(result);
    if (result.errorType === "Unauthorized") {
      localStorage.removeItem("token");
      window.location.href = "/login";
    }
    throw result;
  } else {
    return result;
  }
};
export const DeleteSpace = async (id:any) => {
  debugger
  const result = await spaceService.Delete(id);
  if (isError(result)) {
    ApiErrorNotification(result);
    if (result.errorType === "Unauthorized") {
      localStorage.removeItem("token");
      window.location.href = "/login";
    }
    throw result;
  } else {
    return result;
  }
};
