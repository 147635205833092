import { Button, Col, Image, Modal, Popover, Row } from "antd";
import { useEffect, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import {
  AddReaction,
  DeleteMessage,
  FetchUsersMessages,
  ForwordMessage,
  GetReactions,
  PinMessage,
} from "../../services/chats";
import ChatUsers from "../chatUsers";
import {
  CheckOutlined,
  CloseOutlined,
  FieldTimeOutlined,
} from "@ant-design/icons";
import moment from "moment";
import ChatUsersForward from "../chatUsersForward";
// import {
//   AddRoom,
//   HideMessage,
//   PinMessage,
//   PinOrderOfferMessage,
//   RemoveMessage,
//   RemoveOrderOfferMessage,
// } from "../../services/chats";

function ChatMessage(props: any) {
  const queryClient = useQueryClient();
  const [VisableMenu, setVisableMenu] = useState(false);
  const [ForwordId, setForwordId] = useState<any>(0);
  const {
    content,
    is_mine,
    sender,
    type,
    reply,
    id,
    user,
    text,
    voice,
    image,
    doc,
    audio,
    video,
    replied,
    created_at
  } = props.message;

  useEffect(() => {
    if (ForwordId !== 0) {
      const formData = new FormData();

      formData.append("message_ids[]", id);
      formData.append("chat_ids[]", ForwordId?.id);
      ForwordMessage(formData).then(() => {
        queryClient.invalidateQueries("Messages");
        queryClient.invalidateQueries("groups");
        queryClient.invalidateQueries("UsersChats");
        Modal.destroyAll();
        props.setRoomId(ForwordId?.id);
      });
      setForwordId(0);
    }
  }, [ForwordId]);

  const [UserLoc, setUserLoc] = useState<any>({});
  useEffect(() => {
    const t = JSON.parse(window.localStorage.getItem("user") as string);
    setUserLoc(t);
  }, []);
  const messageClick = (e: any) => {
    if (!VisableMenu) {
      if (e.preventDefault != undefined) e.preventDefault();
      if (e.stopPropagation != undefined) e.stopPropagation();
      setVisableMenu(true);
    }
  };
  useEffect(() => {
    var body = document.querySelector("body");

    body !== null &&
      body.addEventListener(
        "click",
        function (e: any) {
          if (e.target.className !== "messageMenu") setVisableMenu(false);
        },
        false
      );
    window.oncontextmenu = function (e: any) {
      if (e.target.className !== "messageMenu") setVisableMenu(false);
    };
  }, []);
  const [Search, setSearch] = useState<any>("");

  const deleteMessage = () => {
    DeleteMessage(props.message.id).then(() => {
      queryClient.invalidateQueries("Messages");
      queryClient.invalidateQueries("groups");
      queryClient.invalidateQueries("UsersChats");
    });
  };
  const { data, status, isLoading } = useQuery(
    ["UsersChats", Search],
    async () => FetchUsersMessages(1, Search),
    {
      cacheTime: 0,
      staleTime: Infinity,
    }
  );
  const Reactions = useQuery("Reactions", async () => GetReactions(), {
    cacheTime: 0,
    staleTime: Infinity,
  });
  return (
    <div
      onContextMenu={(e) => messageClick(e)}
      className={`messageCon ${is_mine === 1 ? "mine" : ""}`}
    >
      {VisableMenu && (
        <div className={"messageMenu"} id={"messageMenu" + id}>
          <button
            onClick={() =>
              Modal.info({
                className: "forwordModal",
                centered: true,
                title: "Forward",
                autoFocusButton: null,
                icon: (
                  <img
                    style={{
                      transform: "rotateY(180deg)",
                      display: "inline-block",
                    }}
                    src="/images/Arrow_Undo_Up_Left.svg"
                    alt=""
                  />
                ),
                content: (
                  <div>
                    <ChatUsersForward
                      forward
                      data={data?.json}
                      RoomId={0}
                      setUser={(data: any) => setForwordId(data)}
                      setSearch={(data: any) => {
                        setSearch(data);
                      }}
                    />
                  </div>
                ),
                onOk() {},
                okText: "Cancel",
                closeIcon: (
                  <>
                    <CloseOutlined color="#fff" />
                  </>
                ),
                maskClosable: true,
                closable: true,
              })
            }
          >
            <img
              style={{ transform: "rotateY(180deg)" }}
              src="/images/Arrow_Undo_Up_Left.svg"
              alt=""
            />
            Forward
          </button>
          {(props.group &&
            props.user?.props?.find((o: any) => o.perm === 1 || o.perm === 3)
              ?.allow === 1) ||
          (!props.group &&
            (props.user?.members
              ?.find((o: any) => o.id === UserLoc.id)
              .props?.find((o: any) => o.perm === 1)?.allow === 0 ||
              props.user?.members
                ?.find((o: any) => o.id === UserLoc.id)
                .props?.find((o: any) => o.perm === 1) === undefined)) ? (
            <></>
          ) : (
            <button
              onClick={() =>
                props.setReply({
                  id: id,
                  message: type === 1 ? text : "Replies to",
                })
              }
            >
              <img src="/images/Arrow_Undo_Up_Left.svg" alt="" />
              Reply
            </button>
          )}
          {(props.group &&
            props.user?.props?.find((o: any) => o.perm === 4)?.allow === 0) ||
          (!props.group &&
            (props.user?.members
              ?.find((o: any) => o.id === UserLoc.id)
              .props?.find((o: any) => o.perm === 4)?.allow === 0 ||
              props.user?.members
                ?.find((o: any) => o.id === UserLoc.id)
                .props?.find((o: any) => o.perm === 4) === undefined)) ? (
            <></>
          ) : (
            <>
              {is_mine === 1 && type === 1 ? (
                <button
                  onClick={() =>
                    props.setEdit({
                      id: id,
                      message: text,
                    })
                  }
                >
                  <img src="/images/editIcon.svg" alt="" />
                  Edit
                </button>
              ) : (
                <></>
              )}
            </>
          )}
          {props.group &&
          props.user?.props?.find((o: any) => o.perm === 5)?.allow === 0 ? (
            <></>
          ) : (
            <>
              {is_mine === 0 && type === 1 && props.group ? (
                <button
                  onClick={() =>
                    props.setEdit({
                      id: id,
                      message: text,
                    })
                  }
                >
                  <img src="/images/editIcon.svg" alt="" />
                  Edit
                </button>
              ) : (
                <></>
              )}
            </>
          )}
          {type === 1 && (
            <button onClick={() => navigator.clipboard.writeText(text)}>
              <img src="/images/Copy.svg" alt="" />
              Copy
            </button>
          )}

          {/* <button
            onClick={() =>
              PinMessage({
                room_id: props.roomId,
                id: id,
                pinned: 1,
              }).then(() => {
                queryClient.invalidateQueries("Messages");
                queryClient.invalidateQueries("UsersChats");
              })
            }
          >
            <img src="/images/Pin.svg" alt="" />
            Pin
          </button> */}
          {(props.group &&
            props.user?.props?.find((o: any) => o.perm === 4)?.allow === 0) ||
          (!props.group &&
            (props.user?.members
              ?.find((o: any) => o.id === UserLoc.id)
              .props?.find((o: any) => o.perm === 5)?.allow === 0 ||
              props.user?.members
                ?.find((o: any) => o.id === UserLoc.id)
                .props?.find((o: any) => o.perm === 5) === undefined)) ? (
            <></>
          ) : (
            <>
              {is_mine === 1 ? (
                <button onClick={() => deleteMessage()}>
                  <img src="/images/Trash_Full.svg" alt="" />
                  Remove
                </button>
              ) : (
                <></>
              )}
            </>
          )}
          {props.group &&
          props.user?.props?.find((o: any) => o.perm === 5)?.allow === 0 ? (
            <></>
          ) : (
            <>
              {is_mine === 0 && props.group ? (
                <button onClick={() => deleteMessage()}>
                  <img src="/images/Trash_Full.svg" alt="" />
                  Remove
                </button>
              ) : (
                <></>
              )}
            </>
          )}
          <div className="reactionsCon">
            {Reactions.data?.json.map((reaction: any) => (
              <div
                onClick={() =>
                  AddReaction({
                    message_id: id,
                    reaction_id: reaction?.id,
                  }).then(() => {
                    queryClient.invalidateQueries("Messages");
                    queryClient.invalidateQueries("groups");
                    queryClient.invalidateQueries("UsersChats");
                  })
                }
                className="reaction"
              >
                <img src={reaction?.reaction?.url} alt="" />
              </div>
            ))}
          </div>
        </div>
      )}

      {is_mine === 0 && (
        <img className="userImg" src={user?.profile_image?.url} alt="" />
      )}
      <div>
        <div style={{ cursor: "pointer" }} className="messageOwner">
          {is_mine === 0 && sender?.name}
        </div>
        <div className={`message ` + type}>
          {replied && replied !== null && (
            <div className="replyBox">
              {" "}
              {replied?.content ? replied?.content : "Replies to"}
            </div>
          )}
          {!type && <p> {text}</p>}
          {type === 1 && <p> {text}</p>}
          {type === 4 && (
            <p>
              <Image className="messageImg" src={image[0]?.url} />
            </p>
          )}
          {type === 2 && <audio src={voice[0]?.url} controls />}
          {type === 3 && <audio src={audio[0]?.url} controls />}
          {type === 5 && <video src={video[0]?.url} controls />}
          {type === 6 && (
            <div className="fileCard">
              <div style={{ display: "flex" }}>
                <img
                  className="fileIcon"
                  src={
                    is_mine === 1 ? "/images/fileWhite.svg" : "/images/file.svg"
                  }
                  alt=""
                />
                <div className="fileName">{doc[0]?.file_name}</div>
              </div>
              <a href={doc[0]?.url} download target="_blank">
                <img
                  className="downFileIcon"
                  src={
                    is_mine === 1
                      ? "/images/downloadWhite.svg"
                      : "/images/download.svg"
                  }
                  alt=""
                />
              </a>
            </div>
          )}
          {props.message?.reactions && props.message?.reactions.length > 0 && (
            <div className="messageReactions">
              {props.message?.reactions?.map((reaction: any) => (
                <Popover
                  content={<></>}
                  title={
                    <div className="reactionInfo">
                      <img src={reaction.user.profile_image.url} alt="" />
                      {reaction.user.full_name}
                    </div>
                  }
                >
                  <div className="messageReaction">
                    <img src={reaction?.reaction?.url} alt="" />
                  </div>
                </Popover>
              ))}
            </div>
          )}
        </div>
      </div>
      {/* <div className="date">{date}</div> */}
      {is_mine === 1 && (
        <div className="sendedIconCon">
          {props.message?.is_seen === "pending" && (
            <FieldTimeOutlined style={{ transform: "translateY(-5px)" }} />
          )}
          {props.message?.is_seen === 0 && <img src="/images/tick.png" />}
          {props.message?.is_seen === 1 && <img src="/images/two-ticks.png" />}
        </div>
      )}
      <div
        style={{
          marginLeft: 9,
          position: "absolute",
          right: is_mine?"auto":0,
          left:is_mine?0:"auto",
          transform:is_mine?"translate(-120%, 5px)": "translate(110%, 5px)",
          bottom: 0,
        }}
        className="date"
      >
        {moment.utc(created_at).format("MM-DD hh:mm A")}
      </div>
    </div>
  );
}

export default ChatMessage;
