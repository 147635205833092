import { Col, Input, Modal, Row, Tabs, TabsProps } from "antd";
import React, { useContext, useEffect, useState } from "react";
import ChatUsers from "../../chatUsers";
import { QueryClient, useInfiniteQuery, useQuery } from "react-query";
import { FetchGroups } from "../../../services/groups";
import ChatRoom from "../../chatRoom";
import Participants from "../../groupInfo/participants";
import Media from "../../groupInfo/media";
import Files from "../../groupInfo/files";
import Audio from "../../groupInfo/audio";
import Spaces from "../../spaces";
import { FetchMessages } from "../../../services/chats";
import AddGroup from "../../addGroup";
import { FetchSpaces } from "../../../services/spaces";
import { CloseOutlined } from "@ant-design/icons";
import { MessageContext } from "../../../App";

const { Search } = Input;

const Groups = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [ModalSearch, setModalSearch] = useState(false);
  const [RoomId, setRoomId] = useState(0);
  const [SubRoomId, setSubRoomId] = useState(0);
  const [user, setUser] = useState<any>({});
  const [space, setSpace] = useState<any>(0);
  const [UserLoc, setUserLoc] = useState<any>({});
  const [UserLoading, setUsersLoading] = useState<any>(true);
  const [SearchText, setSearch] = useState<any>("");
  const [SearchMessage, setSearchMessage] = useState<any>("");
  const [InputSearchText, setInputSearchText] = useState<any>("");
  const [Lock, setLock] = useState<any>(false);

  useEffect(() => {
    const t = JSON.parse(window.localStorage.getItem("user") as string);
    setUserLoc(t);
  }, []);
  useEffect(() => {
    if (SearchText !== "") setUsersLoading(true);
  }, [SearchText]);
  useEffect(() => {
    setUsersLoading(true);
  }, [space]);
  const queryClient = new QueryClient();

  const { data, status, isLoading, refetch } = useQuery(
    ["groupsData", space, SearchText],
    async () => FetchGroups(1, space, SearchText),
    {
      cacheTime: 0,
      staleTime: Infinity,
    }
  );
  const newMessage = useContext<any>(MessageContext);
  useEffect(() => {
    if (newMessage && Object.keys(newMessage).length > 0) {
      refetch();
    }
  }, [newMessage]);
  useEffect(() => {
    console.log("data.data", data);
    if (data?.json && data?.json.length > 0){
      setUser(data?.json[0]);
    }
    if (data?.json && data?.json.length > 0 && !Lock) {
      setLock(true);
      setRoomId(data?.json[0].id);
    
      if (data?.json[0]?.staff_chat !== undefined) {
        setSubRoomId(data?.json[0]?.staff_chat?.id);
      } else {
        setSubRoomId(0);
      }

      setUsersLoading(false);
    }
  }, [data]);
  const items: TabsProps["items"] = [
    {
      key: "1",
      disabled: user?.props?.find((o: any) => o.perm === 1).allow === 3,
      label: "Participants",
      children: (
        <Participants RoomId={RoomId} close={() => setIsModalOpen(false)} />
      ),
    },
    {
      key: "2",
      label: "Media",
      children: <Media RoomId={RoomId} isModalOpen={isModalOpen} />,
    },
    {
      key: "3",
      label: "Files",
      children: <Files RoomId={RoomId} isModalOpen={isModalOpen} />,
    },
    {
      key: "4",
      label: "Audio",
      children: <Audio RoomId={RoomId} isModalOpen={isModalOpen} />,
    },
  ];

  const searchMessage: any = useInfiniteQuery(
    ["MessagesSearch", SearchMessage, RoomId],
    async ({ pageParam = 1 }) =>
      FetchMessages(RoomId, pageParam, 0, SearchMessage),
    {
      keepPreviousData: false,
      getNextPageParam: (lastPage) => false,
      cacheTime: 0,
      staleTime: Infinity,
      refetchInterval: 10000,
      enabled: RoomId !== undefined && RoomId !== 0 && SearchMessage !== "",
    }
  );
  const SpacesData = useQuery("Spaces", async () => FetchSpaces(), {
    cacheTime: 0,
    staleTime: Infinity,
  });
  const OpenEditGroup = (Group: any) => {
    const createModal = Modal.info({
      className: "adduserModal",
      title: `Edit ${Group.name} group`,
      width: "40%",
      centered: true,
      autoFocusButton: null,
      content: <AddGroup Group={Group} Spaces={SpacesData} />,
      afterClose() {
        queryClient.invalidateQueries("groups");
        queryClient.invalidateQueries("groupsData");
        refetch();
        setIsModalOpen(false);
      },
      okText: "Cancel",
      closeIcon: (
        <>
          <CloseOutlined color="#fff" />
        </>
      ),
      maskClosable: true,
      closable: true,
    });
  };

  return (
    <div className="chat">
      <Row gutter={[25, 0]}>
        <Col span={24}>
          <Spaces setSpace={(space: any) => setSpace(space)} />
        </Col>
        <Col span={8}>
          <ChatUsers
            groups
            loading={UserLoading}
            data={data?.json}
            RoomId={RoomId}
            setRoomId={(Id: any) => {
              setRoomId(Id);
            }}
            setSubRoomId={(Id: any) => setSubRoomId(Id)}
            setUser={(data: any) => {
              setUser(data);
            }}
            setSearch={(data: any) => {
              setSearch(data);
            }}
          />
        </Col>
        <Col span={16}>
          <button
            onClick={() => {
              setIsModalOpen(true);
            }}
            className="infoBtn"
          >
            <img src="/images/info.svg" alt="" />
          </button>
          <button
            onClick={() => {
              setSearchMessage("");
              setModalSearch(true);
            }}
            className="SearchBtn"
          >
            <img src="/images/magnifying-glass.png" alt="" />
          </button>
          <Modal
            className="groupModal"
            open={isModalOpen}
            onCancel={() => setIsModalOpen(false)}
            footer={[]}
          >
            <img src={user?.icon?.url} alt="" className="groupImg" />
            <div className="groupName">{user?.name}</div>
            {user?.description !== null && (
              <div className="groupDes">{user?.description}</div>
            )}
            {(UserLoc?.role === "admin" ||
              UserLoc?.role === "owner" ||
              UserLoc?.role === "sp") && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <button
                  onClick={() => OpenEditGroup(user)}
                  type="button"
                  className="manageBtn"
                  style={{ marginBlock: 15 }}
                >
                  Edit group
                </button>
              </div>
            )}
            <Tabs
              defaultActiveKey={
                user?.props?.find((o: any) => o.perm === 1).allow === 1
                  ? "2"
                  : "1"
              }
              items={items}
            />
          </Modal>
          <Modal
            className="groupModal"
            open={ModalSearch}
            onCancel={() => {
              setModalSearch(false);
              setSearchMessage("");
              setInputSearchText("");
            }}
            afterClose={() => {
              setModalSearch(false);
              setSearchMessage("");
              setInputSearchText("");
            }}
            footer={[]}
            key={user.name}
          >
            <Search
              placeholder={"Seach for message in " + user.name + " group"}
              value={InputSearchText}
              onSearch={(e: any) => setSearchMessage(e)}
              onChange={(e: any) => {
                if (e.target.value === "") setSearchMessage("");
                setInputSearchText(e.target.value);
              }}
            />
            <div className="searchMessageCon">
              {searchMessage?.data?.pages[0]?.json.length === 0 && (
                <div className="notFound">No Result found</div>
              )}
              {searchMessage?.data?.pages[0]?.json.map((searchMsg: any) => (
                <div className="searchMessage">
                  <div className="searchUser">
                    <img src={searchMsg.user?.profile_image?.url} alt="" />
                    {searchMsg?.user?.full_name}
                  </div>
                  <div className="searchMessageText">{searchMsg?.text}</div>
                  <div className="messageDate">
                    {searchMsg?.created_at.split("T")[0]}
                  </div>
                </div>
              ))}
            </div>
          </Modal>
          <div className="prviateUserName">{user?.name}</div>
          {SubRoomId !== 0 && UserLoc !== "client" ? (
            <Tabs
              defaultActiveKey="1"
              items={[
                {
                  key: "1",
                  label: "Clients",
                  children: (
                    <ChatRoom
                      group
                      user={user}
                      roomId={RoomId}
                      setRoomId={(Id: any) => setRoomId(Id)}
                    />
                  ),
                },
                {
                  key: "2",
                  label: "Staff",
                  children: (
                    <ChatRoom
                      group
                      user={user}
                      roomId={SubRoomId}
                      setRoomId={(Id: any) => setRoomId(Id)}
                    />
                  ),
                },
              ]}
            />
          ) : (
            <ChatRoom
              group
              SubRoomId={SubRoomId}
              user={user}
              roomId={RoomId}
              setRoomId={(Id: any) => setRoomId(Id)}
            />
          )}
        </Col>
      </Row>
    </div>
  );
};

export default Groups;
