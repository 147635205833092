import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Upload,
  notification,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { AddFile } from "../../services/contacts";
import { EditSpace, PostSpace } from "../../services/spaces";

const { Option } = Select;

const AddSpace = ({ Groups, onFinish, space }: any) => {
  const [imageUrl, setImageUrl] = useState();
  const [ImageData, setImageData] = useState<any>();
  const [Loading, setLoading] = useState(false);

  useEffect(() => {
    if (space) {
      setImageUrl(space.icon.url);
    }
  }, [space]);
  // useEffect(() => {
  //   if (Groups) {
  //     console.log(
  //       "Groups",
  //       Groups.filter(
  //         (x: any) => x.space_id !== null && x.space_id === space.id
  //       ).map((newSpace: any) => newSpace.id)
  //     );
  //   }
  // }, [Groups]);

  const uploadButton = (
    <img className="placeHolderImg" src="/images/user.png" alt="" />
  );

  const getBase64 = (img: any, callback: any) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };
  const handleChange = (info: any) => {
    var form_data = new FormData();
    form_data.append("file", info.fileList[0].originFileObj);
    form_data.append("type", "1");
    AddFile(form_data).then((res) => {
      setImageData(res?.json?.hash);
      getBase64(info.fileList[0].originFileObj, (url: any) => {
        setImageUrl(url);
      });
      return;
    });
  };
  const submitAddContact = (e: any) => {
    var form_data = new FormData();
    form_data.append("name", e.name);
    if (ImageData) form_data.append("icon", ImageData);
    e.groupsList.map((groupsId: any) => {
      form_data.append("group_ids[]", groupsId);
    });
    setLoading(true);
    if (!space) {
      PostSpace(form_data)
        .then((res: any) => {
          console.log(res?.json.id);
          notification.open({
            type: "success",
            message: "Space " + e.name + " added successfully",
          });
          onFinish();
          Modal.destroyAll();
        })
        .catch(() => {
          setLoading(false);
        });
    } else {
      form_data.append("id", space.id);
      EditSpace(form_data)
        .then((res: any) => {
          console.log(res?.json.id);
          notification.open({
            type: "success",
            message: "Space " + e.name + " Edit successfully",
          });
          onFinish();
          Modal.destroyAll();
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };

  return (
    <div className="userCreateForm">
      <Form
        name="userCreateForm"
        layout="vertical"
        initialValues={{ remember: true }}
        autoComplete="off"
        onFinish={(e) => submitAddContact(e)}
      >
        <Row justify={"center"} gutter={[15, 15]}>
          <Col span={24}>
            <Form.Item name="image">
              <Upload
                name="avatar"
                listType="picture-circle"
                className="avatar-uploader"
                showUploadList={false}
                beforeUpload={() => false}
                accept="image/*"
                onChange={handleChange}
              >
                <div className="placeHolderCon">
                  {imageUrl ? (
                    <img
                      src={imageUrl}
                      alt="avatar"
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                        borderRadius: "50%",
                      }}
                    />
                  ) : (
                    uploadButton
                  )}
                </div>
              </Upload>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="Space Name"
              name="name"
              rules={[
                { required: true, message: "Please input your Space name!" },
              ]}
              initialValue={space?.name}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="List of groups"
              name="groupsList"
              rules={[
                {
                  required: true,
                  message: "Please input your List of groups!",
                },
              ]}
              initialValue={
                space
                  ? Groups?.filter(
                      (x: any) =>
                        x?.space_id !== null && x?.space_id === space?.id
                    ).map((newSpace: any) => newSpace?.id?.toString())
                  : []
              }
            >
              <Select
                mode="multiple"
                style={{ width: "100%" }}
                bordered={false}
                // defaultValue={Groups.filter(
                //   (x: any) => x.space_id !== null && x.space_id === space.id
                // ).map((newSpace: any) => newSpace.name)}
              >
                {Groups?.map((user: any) => (
                  <Option key={user?.id.toString()}>{user?.name}</Option>
                ))}
              </Select>
              {/* <Checkbox.Group>
                <div className="chatUsers">
                  {Groups.map((user: any) => (
                    <div className={"userCard"}>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <div className="cardImgCon">
                          <div className="imgPlaceHolder">
                            <img src={user?.icon?.url} alt="" />
                          </div>
                        </div>
                        <div className="cardText">
                          <div className="cardTitle">{user?.name}</div>
                        </div>
                      </div>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <Checkbox value={user?.id} />
                      </div>
                    </div>
                  ))}
                </div>
              </Checkbox.Group> */}
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item>
              <Button loading={Loading} type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default AddSpace;
