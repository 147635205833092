import React, { useEffect, useState } from "react";
import { FetchMember } from "../../../services/chats";
import { useQuery } from "react-query";
import ManageUsers from "../../addGroup/mageUsers";
import { Modal, notification } from "antd";
import { FetchContacts } from "../../../services/contacts";
import { UpdatePerm } from "../../../services/groups";
import { CloseOutlined } from "@ant-design/icons";

const Participants = ({ RoomId, close }: any) => {
  const [Perms, setPerms] = useState<any>([]);
  const [User, setUser] = useState<any>({});
  useEffect(() => {
    console.log("PermsuseEffect", Perms);
  }, [Perms]);
  useEffect(() => {
    const t = JSON.parse(window.localStorage.getItem("user") as string);
    setUser(t);
  }, []);
  const { data, status, isLoading, refetch } = useQuery(
    ["member", RoomId],
    async () => FetchMember(RoomId),
    {
      cacheTime: 0,
      staleTime: Infinity,
      enabled: RoomId !== 0,
    }
  );
  const Contacts = useQuery(["Contacts"], async () => FetchContacts(0), {
    cacheTime: 0,
    staleTime: Infinity,
  });
  const ManageMember = () => {
    console.log("Perms", Perms);
    var form_data = new FormData();
    form_data.append("id", RoomId);
    Perms.map((per: any, index: any) => {
      form_data.append(`user_ids[${index}][id]`, per.id);
      per.perm.map((perId: any, newIndex: any) => {
        form_data.append(
          `user_ids[${index}][permissions][${newIndex}][perm]`,
          perId
        );
        form_data.append(
          `user_ids[${index}][permissions][${newIndex}][allow]`,
          "1"
        );
      });
    });
    UpdatePerm(form_data).then((res: any) => {
      refetch();
      notification.open({
        type: "success",
        message: "Group members updated successfully",
      });
      Modal.destroyAll();
    });
  };
  const CreateManageOpenModal = () => {
    let newParms: any = [];
    const createModal = Modal.info({
      className: "manageModal",
      title: (
        <div className="modalheader">
          <div className="modalTitle">Choose group Participants</div>
          <div className="modalSubtitle">
            Select group members and their permissions individually
          </div>
        </div>
      ),
      width: "40%",
      footer: [],
      autoFocusButton: null,
      content: (
        <ManageUsers
          members={data?.json?.members}
          Perms={Perms}
          setPerms={(newParms: any) => {
            console.log("newParms", newParms);
            setPerms([...newParms]);
          }}
          Contacts={Contacts}
          RoomId={RoomId}
        />
      ),
      onOk() {
        refetch();
      },
      onCancel() {
        refetch();
      },
      afterClose() {
        refetch();
      },
      closeIcon: (
        <>
          <CloseOutlined color="#fff" />
        </>
      ),
      maskClosable: true,
      closable: true,
    });
  };
  return (
    <div className="participants">
      <div className="chatUsers">
        {(User?.role === "admin" ||
          User?.role === "owner" ||
          User?.role === "sp")&&(

        <div
          style={{
            display: "flex",
            justifyContent: "end",
            alignItems: "center",
          }}
        >
          <button
            onClick={() => CreateManageOpenModal()}
            type="button"
            className="manageBtn"
            disabled={data?.json?.members.length === 0}
          >
            Manage Participants
          </button>
        </div>
          )}
        {data?.json &&
          data?.json?.members.map((user: any, index: number) => (
            <div className={"userCard"}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <div className="cardImgCon">
                  <div className="imgPlaceHolder">
                    <img src={user?.profile_image?.url} alt="" />
                  </div>
                </div>
                <div className="cardText">
                  <div className="cardTitle">{user?.full_name}</div>
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default Participants;
