import { Avatar, Input, List, Modal, Skeleton } from "antd";
import React, { useEffect, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import AddGroup from "../addGroup";
import { FetchContacts } from "../../services/contacts";
import { FetchSpaces } from "../../services/spaces";
import { CloseOutlined } from "@ant-design/icons";

const { Search } = Input;

const AddContactModal = ({
  setRoomId,
  setUser,
  data,
  RoomId,
  setSubRoomId,
  forward,
  loading,
  setSearch,
  groups,
}: any) => {
  const [UserLoc, setUserLoc] = useState<any>({});
  useEffect(() => {
    const t = JSON.parse(window.localStorage.getItem("user") as string);
    setUserLoc(t);
  }, []);

  return (
    <div className="chatUsers">
      {loading ? (
        <div style={{ padding: " 30px 30px" }}>
          <Skeleton style={{ marginBottom: 20 }} active avatar />
          <Skeleton style={{ marginBottom: 20 }} active avatar />
          <Skeleton style={{ marginBottom: 20 }} active avatar />
          <Skeleton style={{ marginBottom: 20 }} active avatar />
          <Skeleton style={{ marginBottom: 20 }} active avatar />
        </div>
      ) : (
        <>
          {data &&
            data.map((user: any, index: number) => (
              <div
                onClick={() => setUser(user?.contact?.id)}
                className={"userCard"}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div className="cardImgCon">
                    <div className="imgPlaceHolder">
                      <img src={user?.contact?.profile_image?.url} alt="" />
                    </div>
                  </div>
                  <div className="cardText">
                    <div className="cardTitle">{user?.contact?.full_name}</div>
                  </div>
                </div>
              </div>
            ))}
        </>
      )}
    </div>
  );
};

export default AddContactModal;
