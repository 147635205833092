import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Spin,
  Upload,
  notification,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import React, { useEffect, useState } from "react";
import PermissionsList from "./permissionsList";
import ManageUsers from "./mageUsers";
import { AddFile } from "../../services/contacts";
import { EditGroup, postGroup } from "../../services/groups";
import { CloseOutlined, LoadingOutlined } from "@ant-design/icons";

const { Option } = Select;

const AddGroup = ({ role, onFinish, Contacts, Spaces, Group }: any) => {
  const [imageUrl, setImageUrl] = useState();
  const [ImageData, setImageData] = useState();
  const [Loading, setLoading] = useState(false);
  const [Perms, setPerms] = useState<any>([]);

  const [ImageLoading, setImageLoading] = useState(false);

  useEffect(() => {
    if (Group) {
      setImageUrl(Group?.icon?.url);
    }
  }, [Group]);
  useEffect(() => {
    console.log("PermsOut", Perms);
  }, [Perms]);
  const uploadButton = (
    <img className="placeHolderImg" src="/images/user.png" alt="" />
  );

  const getBase64 = (img: any, callback: any) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };
  const handleChange = (info: any) => {
    setImageLoading(true);
    var form_data = new FormData();
    form_data.append("file", info.fileList[0].originFileObj);
    form_data.append("type", "1");
    AddFile(form_data).then((res: any) => {
      setImageData(res?.json?.hash);
      getBase64(info.fileList[0].originFileObj, (url: any) => {
        setImageUrl(url);
        setImageLoading(false);
      });
      return;
    });
  };
  const submitAddContact = (e: any) => {
    console.log(e);
    var form_data = new FormData();
    if (ImageData) form_data.append("icon", ImageData);
    if (e.space && e.space !== 0 && e.space !== "0")
      form_data.append("space_id", e.space);
    form_data.append("name", e.name);
    form_data.append("description", e.description);

    setLoading(true);
    if (!Group) {
      Perms.map((per: any, index: any) => {
        form_data.append(`user_ids[${index}][id]`, per.id);
        per.perm.map((perId: any, newIndex: any) => {
          form_data.append(
            `user_ids[${index}][permissions][${newIndex}][perm]`,
            perId
          );
          form_data.append(
            `user_ids[${index}][permissions][${newIndex}][allow]`,
            "1"
          );
        });
      });
      postGroup(form_data)
        .then((res: any) => {
          console.log(res?.json.id);
          notification.open({
            type: "success",
            message: "Group " + e.name + " added successfully",
          });
          Modal.destroyAll();
        })
        .catch(() => {
          setLoading(false);
        });
    } else {
      form_data.append("id", Group.id);
      EditGroup(form_data)
        .then((res: any) => {
          console.log(res?.json.id);
          notification.open({
            type: "success",
            message: "Group " + e.name + " edited successfully",
          });
          Modal.destroyAll();
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };

  const CreateManageOpenModal = () => {
    console.log("Contacts1", Contacts);
    const createModal = Modal.info({
      className: "manageModal",
      title: (
        <div className="modalheader">
          <div className="modalTitle">Choose group Participants</div>
          <div className="modalSubtitle">
            Select group members and their permissions individually
          </div>
        </div>
      ),
      width: "40%",
      okText: "Confirm",

      content: (
        <ManageUsers
          Perms={Perms}
          setPerms={(newParms: any) => {
            console.log(newParms);
            setPerms([...newParms]);
          }}
          Contacts={Contacts}
        />
      ),
      onOk() {},
      closeIcon: (
        <>
          <CloseOutlined color="#fff" />
        </>
      ),
      maskClosable: true,
      closable: true,
      autoFocusButton:null,
    });
  };
  return (
    <div className="userCreateForm">
      <Form
        name="userCreateForm"
        layout="vertical"
        initialValues={{ remember: true }}
        autoComplete="off"
        onFinish={(e) => submitAddContact(e)}
      >
        <Row justify={"center"} gutter={[15, 15]}>
          <Col span={24}>
            <Form.Item name="image">
              <Upload
                name="avatar"
                listType="picture-circle"
                className="avatar-uploader"
                showUploadList={false}
                beforeUpload={() => false}
                accept="image/*"
                onChange={handleChange}
              >
                <div className="placeHolderCon">
                  {ImageLoading ? (
                    <>
                      <Spin
                        indicator={
                          <LoadingOutlined style={{ fontSize: 24 }} spin />
                        }
                      />
                    </>
                  ) : (
                    <>
                      {imageUrl ? (
                        <img
                          src={imageUrl}
                          alt="avatar"
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                            borderRadius: "50%",
                          }}
                        />
                      ) : (
                        uploadButton
                      )}
                    </>
                  )}
                </div>
              </Upload>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="Group Name"
              name="name"
              rules={[
                { required: true, message: "Please input your Group name!" },
              ]}
              initialValue={Group?.name}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="description"
              name="description"
              rules={[
                {
                  required: true,
                  message: "Please input the Group description!",
                },
              ]}
              initialValue={Group?.description}
            >
              <TextArea autoSize={{ minRows: 2, maxRows: 10 }} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name="space"
              label="Assign to space"
              initialValue={Group?.space_id}
            >
              <Select bordered={false} defaultValue={"none"}>
                <Select.Option value="0">none</Select.Option>
                {Spaces.data?.json.map((space: any) => (
                  <Select.Option value={space.id}>{space.name}</Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          {!Group && (
            <Col span={24}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                  alignItems: "center",
                }}
              >
                <button
                  onClick={() => CreateManageOpenModal()}
                  type="button"
                  className="manageBtn"
                >
                  Manage Participants
                </button>
              </div>
              <div className="chatUsers">
                {Contacts?.data?.json.map((user: any) => (
                  <>
                    {Perms.filter((e: any) => e.id === user?.contact?.id)
                      .length > 0 && (
                      <div className={"userCard"}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <div className="cardImgCon">
                            <div className="imgPlaceHolder">
                              <img
                                src={user?.contact?.profile_image?.url}
                                alt=""
                              />
                            </div>
                          </div>
                          <div className="cardText">
                            <div className="cardTitle">
                              {user?.contact?.full_name}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                ))}
              </div>
            </Col>
          )}
          <Col span={24}>
            <Form.Item>
              <Button loading={Loading} type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default AddGroup;
