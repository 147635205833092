import React, { useState } from "react";
import { Button, Checkbox, Modal, Radio, Tabs } from "antd";
import { useEffect } from "react";

const PermissionsList = ({setRoles,Permissions}) => {
  const [Selected, setSelected] = useState(Permissions?Permissions:[]);
  useEffect(() => {
    setRoles(Selected);
  }, [Selected]);

  return (
    <>
      <div className="rolesButtons">
        <button
          onClick={() => setSelected([1])}
          className={`rolesButton ${Selected?.includes(1) ? "active" : ""}`}
        >
          <img src="/images/message-tick.png" />
          Read-Only
        </button>
        <button
          onClick={() => setSelected([3])}
          className={`rolesButton ${Selected.includes(3) ? "active" : ""}`}
        >
          <img src="/images/ghost.png" />
          Hidden
        </button>
        <button
          onClick={() => {
            if (!Selected.includes(2)) setSelected([2]);
          }}
          className={`rolesButton ${Selected.includes(2) ? "active" : ""}`}
        >
          <img src="/images/messages.png" />
          Read-Write
        </button>
        {Selected.includes(2) && (
          <>
            <button
              onClick={() =>
                !Selected.includes(4)
                  ? setSelected([...Selected, 4])
                  : setSelected(
                      Selected.filter(function (item) {
                        return item !== 4;
                      })
                    )
              }
              className={`rolesButton ${Selected.includes(4) ? "active" : ""}`}
            >
              <img src="/images/user-edit.png" />
              Moderate own
            </button>
            <button
              onClick={() =>
                !Selected.includes(5)
                  ? setSelected([...Selected, 5])
                  : setSelected(
                      Selected.filter(function (item) {
                        return item !== 5;
                      })
                    )
              }
              className={`rolesButton ${Selected.includes(5) ? "active" : ""}`}
            >
              <img src="/images/briefcase.png" />
              Moderate others
            </button>
          </>
        )}
      </div>
      {/* <Button
        onClick={() => AddContectHandle()}
        className="ConfirmButton"
        type="primary"
      >
        Confirm
      </Button> */}
    </>
  );
};

export default PermissionsList;
