import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Upload,
  UploadProps,
  notification,
} from "antd";
import { RcFile, UploadChangeParam, UploadFile } from "antd/es/upload";
import React, { useState } from "react";
import { AddContact, AddFile } from "../../../services/contacts";
import { useQueryClient } from "react-query";
import MyContactList from "../myContactList";

const { Option } = Select;

const AddUser = ({ role, onFinish }: any) => {
  const [imageUrl, setImageUrl] = useState<string>();
  const [ImageData, setImageData] = useState<any>();
  const [Loading, setLoading] = useState<any>(false);
  const [ImageLoading, setImageLoading] = useState<any>(false);

  const prefixSelector = (
    <Form.Item name="prefix" noStyle>
      <Select style={{ width: 70 }}>
        <Option value="963">+963</Option>
        <Option value="966">+966</Option>
      </Select>
    </Form.Item>
  );
  const uploadButton = (
    <img className="placeHolderImg" src="/images/user.png" alt="" />
  );

  const getBase64 = (img: RcFile, callback: (url: string) => void) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result as string));
    reader.readAsDataURL(img);
  };
  const handleChange = (info: any) => {
    var form_data = new FormData();
    form_data.append("file", info.fileList[0].originFileObj);
    form_data.append("type", "1");
    AddFile(form_data).then((res) => {
      setImageData(res?.json?.hash);
      getBase64(info.fileList[0].originFileObj as RcFile, (url) => {
        setImageUrl(url);
      });
      return;
    });
  };
  const submitAddContact = (e: any) => {
    console.log(e);
    var form_data = new FormData();

    for (var key in e) {
      if (key !== "image" && key !== "prefix" && key !== "phone")
        form_data.append(key, e[key]);
      else if (key === "image" && ImageData) form_data.append(key, ImageData);
    
    }
    form_data.append("role", role);
    setLoading(true);
    AddContact(form_data)
      .then((res) => {
        console.log(res?.json.id)
        notification.open({
          type: "success",
          message:
            "User " + e.first_name + " " + e.last_name + " added successfully",
        });
        onFinish();
        Modal.destroyAll();
      })
      .catch(() => {
        setLoading(false);
      });
  };
  return (
    <div className="userCreateForm">
      <Form
        name="userCreateForm"
        layout="vertical"
        initialValues={{ remember: true }}
        autoComplete="off"
        onFinish={(e) => submitAddContact(e)}
      >
        <Row justify={"center"} gutter={[15, 15]}>
          <Col span={24}>
            <Form.Item name="image">
              <Upload
                name="avatar"
                listType="picture-circle"
                className="avatar-uploader"
                showUploadList={false}
                beforeUpload={() => false}
                accept="image/*"
                onChange={handleChange}
              >
                <div className="placeHolderCon">
                  {imageUrl ? (
                    <img
                      src={imageUrl}
                      alt="avatar"
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                        borderRadius: "50%",
                      }}
                    />
                  ) : (
                    uploadButton
                  )}
                </div>
              </Upload>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="First name"
              name="first_name"
              rules={[
                {
                  required: true,
                  message: "Please input your First name!",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Last name"
              name="last_name"
              rules={[
                { required: true, message: "Please input your Last name!" },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="Username"
              name="username"
              rules={[
                { required: true, message: "Please input your username!" },
              ]}
            >
              <Input autoComplete={"new-username"} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name="phone"
              label="Phone Number"
              rules={[
                {
                  required: true,
                  message: "Please input your phone number!",
                },
              ]}
            >
              <Input type="number" style={{ width: "100%" }} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="Password"
              name="password"
              rules={[
                { required: true, message: "Please input your password!" },
              ]}
            >
              <Input.Password autoComplete={"new-password"} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name="password_confirmation"
              label="Confirm Password"
              dependencies={["password"]}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: "Please confirm your password!",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(
                        "The new password that you entered do not match!"
                      )
                    );
                  },
                }),
              ]}
            >
              <Input.Password />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item>
              <Button loading={Loading} type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default AddUser;
