import { Avatar, Input, List, Modal, Skeleton } from "antd";
import React, { useEffect, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import AddGroup from "../addGroup";
import { FetchContacts } from "../../services/contacts";
import { FetchSpaces } from "../../services/spaces";
import { CloseOutlined } from "@ant-design/icons";

const { Search } = Input;

const ChatUsersForward = ({
  setRoomId,
  setUser,
  data,
  RoomId,
  setSubRoomId,
  forward,
  loading,
  setSearch,
  groups,
}: any) => {
  const [UserLoc, setUserLoc] = useState<any>({});
  useEffect(() => {
    const t = JSON.parse(window.localStorage.getItem("user") as string);
    setUserLoc(t);
  }, []);


  return (
    <div className="chatUsers">
      {loading ? (
        <div style={{ padding: " 30px 30px" }}>
          <Skeleton style={{ marginBottom: 20 }} active avatar />
          <Skeleton style={{ marginBottom: 20 }} active avatar />
          <Skeleton style={{ marginBottom: 20 }} active avatar />
          <Skeleton style={{ marginBottom: 20 }} active avatar />
          <Skeleton style={{ marginBottom: 20 }} active avatar />
        </div>
      ) : (
        <>
          {data &&
            data.map((user: any, index: number) => (
              <div
                onClick={() => {
                  if (!forward) setRoomId(user.id);
                  setUser(user);
                  if (!forward) {
                    if (user?.staff_chat !== undefined)
                      setSubRoomId(user?.staff_chat?.id);
                    else setSubRoomId(0);
                  }
                }}
                className={RoomId === user.id ? "userCard active" : "userCard"}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div className="cardImgCon">
                    <div className="imgPlaceHolder">
                      <img
                        src={
                          !groups
                            ? user?.members?.find(
                                (item: any) => item.id !== UserLoc.id
                              )?.profile_image?.url
                            : user?.icon?.url
                        }
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="cardText">
                    <div className="cardTitle">{user?.name}</div>
                    <div className="cardDes">
                      {!forward && user?.last_message?.content}
                    </div>
                  </div>
                </div>
                <div className="cardDetails">
                  <div className="cardTime">
                    {!forward && user?.last_message?.created_at?.split("T")[0]}
                  </div>
                  {!forward && user?.unread_messages_count !== 0 && (
                    <div className="messageCount">
                      {user?.unread_messages_count}
                    </div>
                  )}
                </div>
              </div>
            ))}
        </>
      )}
 
    </div>
  );
};

export default ChatUsersForward;
