import React, { useEffect, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { FetchSpaces } from "../../services/spaces";
import AddSpace from "../addSpace";
import { Modal } from "antd";
import { FetchGroups } from "../../services/groups";
import ManageSpaces from "../manageSpaces";
import { CloseOutlined, SettingOutlined } from "@ant-design/icons";

const Spaces = ({ setSpace }: any) => {
  const [Selected, setSelected] = useState(0);
  const [User, setUser] = useState<any>({});
  const queryClient = useQueryClient();
  useEffect(() => {
    setSpace(Selected);
  }, [Selected]);
  useEffect(() => {
    const t = JSON.parse(window.localStorage.getItem("user") as string);
    console.log(t);
    setUser(t);
  }, []);
  const { data, status, isLoading, refetch } = useQuery(
    "Spaces",
    async () => FetchSpaces(),
    {
      cacheTime: 0,
      staleTime: Infinity,
    }
  );
  const groups = useQuery(
    ["groups", 0, ""],
    async () => FetchGroups(1, 0, ""),
    {
      cacheTime: 0,
      staleTime: Infinity,
    }
  );
  const openAddSpace = () => {
    const createModal = Modal.info({
      className: "adduserModal",
      title: "Spaces",
      width: "40%",
      centered: true,
      autoFocusButton: null,
      content: (
        <ManageSpaces
          Groups={groups.data?.json}
          onFinish={() => refetch()}
          Spaces={data}
        />
      ),
      onOk() {},
      okText: "Cancel",
      closeIcon: (
        <>
          <CloseOutlined color="#fff" />
        </>
      ),
      afterClose() {
        refetch()
      },
      maskClosable: true,
      closable: true,
    });
  };
  return (
    <div className="spacesCon">
      {(User?.role === "admin" || User?.role === "owner" || User?.role === "sp")&&(
        <div onClick={() => openAddSpace()} className="space">
          <div
            style={{
              display: "flex",
              alignContent: "center",
              justifyContent: "center",
              fontSize: 20,
            }}
            className="imageCon"
          >
            <SettingOutlined color="#fff" />
          </div>
          <div className="spaceTitle" style={{ width: 60 }}>
            Manage
          </div>
        </div>
      )}
      <div
        onClick={() => setSelected(0)}
        className={Selected === 0 ? "space active" : "space"}
      >
        <div className="imageCon">
          <img
            style={{ transform: "scale(0.7)" }}
            src="/images/logo.jpg"
            alt=""
          />
        </div>
        <div className="spaceTitle">All</div>
      </div>
      {data?.json?.map((space: any) => (
        <div
          onClick={() => setSelected(space.id)}
          className={Selected === space.id ? "space active" : "space"}
        >
          <div className="imageCon">
            <img src={space.icon.url} alt="" />
          </div>
          <div className="spaceTitle">{space.name}</div>
        </div>
      ))}
    </div>
  );
};

export default Spaces;
