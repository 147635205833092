import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Tabs,
  TabsProps,
} from "antd";
import React, { useEffect, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { FetchContacts } from "../../services/contacts";
import AddUser from "./addUser";
import MyContactList from "./myContactList";
import { CloseOutlined } from "@ant-design/icons";

const { Option } = Select;
const ManageModal = ({ isModalOpen, setIsModalOpen }: any) => {
  const [CanEdit, setCanEdit] = useState(0);
  const { data, status, isLoading, refetch } = useQuery(
    ["Contacts"],
    async () => FetchContacts(0),
    {
      cacheTime: 0,
      staleTime: Infinity,
    }
  );

  const queryClient = useQueryClient();
  const [UserLoc, setUserLoc] = useState<any>({});
  useEffect(() => {
    const t = JSON.parse(window.localStorage.getItem("user") as string);
    setUserLoc(t);
  }, []);
  console.log(
    "client",
    data?.json?.filter((item: any) => item?.contact?.role === "client")
  );
  console.log(
    "owner",
    data?.json?.filter((item: any) => item?.contact?.role === "owner")
  );
  console.log(
    "op",
    data?.json?.filter((item: any) => item?.contact?.role === "op")
  );
  console.log(
    "su",
    data?.json?.filter((item: any) => item?.contact?.role === "su")
  );
  console.log(
    "staff",
    data?.json?.filter((item: any) => item?.contact?.role === "staff")
  );
  console.log(
    "admin",
    data?.json?.filter((item: any) => item?.contact?.role === "admin")
  );

  const OpenEditPopUp = (user: any) => {
    if (CanEdit == 0) {
      setCanEdit(user?.contact?.id);
      FetchContacts(user?.contact?.id)
        .then((res) => {
          Modal.info({
            className: "MyContactList",
            title: (
              <div className="modalheader">
                <div className="modalTitle">WhiteList For New User</div>
                <div className="modalSubtitle">
                  Choose the user's whiteList an their permission
                </div>
              </div>
            ),
            width: "40%",
            autoFocusButton:null,
            content: (
              <MyContactList
                refetch={() => refetch()}
                currentUser={user}
                checkedUsers={res.json}
                data={data}
              />
            ),
            onOk() {},
            okText: "Cancel",
            closeIcon: (
              <>
                <CloseOutlined color="#fff" />
              </>
            ),
            maskClosable: true,
            closable: true,
          });
          setCanEdit(0);
        })
        .catch(() => {
          setCanEdit(0);
        });
    }
  };

  const items: TabsProps["items"] = [
    {
      ...(data?.json &&
        data?.json?.filter((item: any) => item?.contact?.role === "client")
          .length > 0 && {
          key: "clients",
          label: "Clients",
          children: (
            <div className="chatUsers">
              {data?.json
                ?.filter((item: any) => item?.contact?.role === "client")
                .map((user: any) => (
                  <div className={"userCard"}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div className="cardImgCon">
                        <div className="imgPlaceHolder">
                          <img src={user?.contact?.profile_image?.url} alt="" />
                        </div>
                      </div>
                      <div className="cardText">
                        <div className="cardTitle">
                          {user?.contact?.full_name}
                        </div>
                      </div>
                    </div>
                    <Button
                      onClick={(e) => OpenEditPopUp(user)}
                      className="editButton"
                      loading={CanEdit === user?.contact?.id}
                    >
                      <img src="/images/editIconLight.svg" alt="" />
                      Edit
                    </Button>
                  </div>
                ))}
            </div>
          ),
        }),
    },
    {
      ...(data?.json &&
        data?.json?.filter((item: any) => item?.contact?.role === "staff")
          .length > 0 && {
          key: "staff",
          label: "Staff",
          children: (
            <div className="chatUsers">
              {data?.json
                ?.filter((item: any) => item?.contact?.role === "staff")
                .map((user: any) => (
                  <div className={"userCard"}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div className="cardImgCon">
                        <div className="imgPlaceHolder">
                          <img src={user?.contact?.profile_image?.url} alt="" />
                        </div>
                      </div>
                      <div className="cardText">
                        <div className="cardTitle">
                          {user?.contact?.full_name}
                        </div>
                      </div>
                    </div>
                    <Button
                      onClick={(e) => OpenEditPopUp(user)}
                      className="editButton"
                      loading={CanEdit === user?.contact?.id}
                    >
                      <img src="/images/editIconLight.svg" alt="" />
                      Edit
                    </Button>
                  </div>
                ))}
            </div>
          ),
        }),
    },
    {
      ...(data?.json &&
        data?.json?.filter((item: any) => item?.contact?.role === "su").length >
          0 && {
          key: "su",
          label: "SU",
          children: (
            <div className="chatUsers">
              {data?.json
                ?.filter((item: any) => item?.contact?.role === "su")
                .map((user: any) => (
                  <div className={"userCard"}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div className="cardImgCon">
                        <div className="imgPlaceHolder">
                          <img src={user?.contact?.profile_image?.url} alt="" />
                        </div>
                      </div>
                      <div className="cardText">
                        <div className="cardTitle">
                          {user?.contact?.full_name}
                        </div>
                      </div>
                    </div>
                    <Button
                      onClick={(e) => OpenEditPopUp(user)}
                      className="editButton"
                      loading={CanEdit === user?.contact?.id}
                    >
                      <img src="/images/editIconLight.svg" alt="" />
                      Edit
                    </Button>
                  </div>
                ))}
            </div>
          ),
        }),
    },
    {
      ...(data?.json &&
        data?.json?.filter((item: any) => item?.contact?.role === "op").length >
          0 && {
          key: "op",
          label: "OP",
          children: (
            <div className="chatUsers">
              {data?.json
                ?.filter((item: any) => item?.contact?.role === "op")
                .map((user: any) => (
                  <div className={"userCard"}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div className="cardImgCon">
                        <div className="imgPlaceHolder">
                          <img src={user?.contact?.profile_image?.url} alt="" />
                        </div>
                      </div>
                      <div className="cardText">
                        <div className="cardTitle">
                          {user?.contact?.full_name}
                        </div>
                      </div>
                    </div>
                    <Button
                      onClick={(e) => OpenEditPopUp(user)}
                      className="editButton"
                      loading={CanEdit === user?.contact?.id}
                    >
                      <img src="/images/editIconLight.svg" alt="" />
                      Edit
                    </Button>
                  </div>
                ))}
            </div>
          ),
        }),
    },
    {
      ...(data?.json &&
        data?.json?.filter((item: any) => item?.contact?.role === "admin")
          .length > 0 && {
          key: "admin",
          label: "Admins",
          closeIcon: false,
          children: (
            <div className="chatUsers">
              {data?.json
                ?.filter((item: any) => item?.contact?.role === "admin")
                .map((user: any) => (
                  <div className={"userCard"}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div className="cardImgCon">
                        <div className="imgPlaceHolder">
                          <img src={user?.contact?.profile_image?.url} alt="" />
                        </div>
                      </div>
                      <div className="cardText">
                        <div className="cardTitle">
                          {user?.contact?.full_name}
                        </div>
                      </div>
                    </div>
                    <Button
                      onClick={(e) => OpenEditPopUp(user)}
                      className="editButton"
                      loading={CanEdit === user?.contact?.id}
                    >
                      <img src="/images/editIconLight.svg" alt="" />
                      Edit
                    </Button>
                  </div>
                ))}
            </div>
          ),
        }),
    },
    {
      ...(data?.json &&
        data?.json?.filter((item: any) => item?.contact?.role === "owner")
          .length > 0 && {
          key: "owner",
          label: "Owners",
          children: (
            <div className="chatUsers">
              {data?.json
                ?.filter((item: any) => item?.contact?.role === "owner")
                .map((user: any) => (
                  <div className={"userCard"}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div className="cardImgCon">
                        <div className="imgPlaceHolder">
                          <img src={user?.contact?.profile_image?.url} alt="" />
                        </div>
                      </div>
                      <div className="cardText">
                        <div className="cardTitle">
                          {user?.contact?.full_name}
                        </div>
                      </div>
                    </div>
                    <Button
                      onClick={(e) => OpenEditPopUp(user)}
                      className="editButton"
                      loading={CanEdit === user?.contact?.id}
                    >
                      <img src="/images/editIconLight.svg" alt="" />
                      Edit
                    </Button>
                  </div>
                ))}
            </div>
          ),
        }),
    },
  ];

  const CreateUserOpenModal = (userId: number) => {
    const createModal = Modal.info({
      className: "adduserModal",
      title: "Create new user",
      width: "40%",
      autoFocusButton:null,
      content: (
        <AddUser
          data={data}
          role={userId}
          onFinish={() => {
            refetch();
          }}
        />
      ),
      onOk() {},
      okText: "Cancel",
      closeIcon: (
        <>
          <CloseOutlined color="#fff" />
        </>
      ),
      maskClosable: true,
      closable: true,
    });
  };
  return (
    <Modal
      className="manageModal"
      open={isModalOpen}
      onCancel={() => setIsModalOpen(false)}
      footer={[
        <Button
          onClick={() =>
            Modal.info({
              className: "adduserModal adduserModalSe",
              centered: true,
              title: "Adding new user",
              autoFocusButton:null,
              content: (
                <div className="usersTypes">
                  <Row justify={"center"} gutter={[15, 15]}>
                    {UserLoc.role === "op" && (
                      <Col span={12}>
                        <div
                          onClick={() => CreateUserOpenModal(2)}
                          className="usersType"
                        >
                          <img
                            src="/images/user.svg"
                            alt=""
                            className="cardImg"
                          />
                          <div className="cardTitle">Add New Owner</div>
                        </div>
                      </Col>
                    )}
                    {(UserLoc.role === "op" || UserLoc.role === "owner") && (
                      <Col span={12}>
                        <div
                          onClick={() => CreateUserOpenModal(1)}
                          className="usersType"
                        >
                          <img
                            src="/images/user.svg"
                            alt=""
                            className="cardImg"
                          />
                          <div className="cardTitle">Add New Super Admin</div>
                        </div>
                      </Col>
                    )}
                    {(UserLoc.role === "op" ||
                      UserLoc.role === "owner" ||
                      UserLoc.role === "su") && (
                      <Col span={12}>
                        <div
                          onClick={() => CreateUserOpenModal(4)}
                          className="usersType"
                        >
                          <img
                            src="/images/user.svg"
                            alt=""
                            className="cardImg"
                          />
                          <div className="cardTitle">Add New Admin</div>
                        </div>
                      </Col>
                    )}
                    {UserLoc.role !== "client" && UserLoc.role !== "staff" && (
                      <Col span={12}>
                        <div
                          onClick={() => CreateUserOpenModal(5)}
                          className="usersType"
                        >
                          <img
                            src="/images/people.png"
                            alt=""
                            className="cardImg"
                          />
                          <div className="cardTitle">Add New Staff</div>
                        </div>
                      </Col>
                    )}
                    {UserLoc.role !== "client" && UserLoc.role !== "staff" && (
                      <Col span={12}>
                        <div
                          onClick={() => CreateUserOpenModal(6)}
                          className="usersType"
                        >
                          <img
                            src="/images/user.svg"
                            alt=""
                            className="cardImg"
                          />
                          <div className="cardTitle">Add New Client</div>
                        </div>
                      </Col>
                    )}
                  </Row>
                </div>
              ),
              onOk() {},
              okText: "Cancel",
              closeIcon: (
                <>
                  <CloseOutlined color="#fff" />
                </>
              ),
              maskClosable: true,
              closable: true,
            })
          }
          className="addUserBtn"
        >
          Add new user
        </Button>,
      ]}
    >
      <Tabs defaultActiveKey={"1"} items={items} />
    </Modal>
  );
};

export default ManageModal;
