import { Switch } from "antd";
import React, { useEffect, useState } from "react";

const Permissions = ({ user, prmissionList,setPermission }: any) => {
  const [SeeMessages, setSeeMessages] = useState(false);
  const [CallVoice, setCallVoice] = useState(false);
  const [CallVideo, setCallVideo] = useState(false);
  const [Text, setText] = useState(false);
  const [Edit, setEdit] = useState(false);
  const [deleteP, setDelete] = useState(false);
  const [deleteThread, setdeleteThread] = useState(false);

  useEffect(() => {
    let perm = [];
    if (SeeMessages) perm.push({ perm: 7, allow: 1 });
    if (CallVoice) perm.push({ perm: 2, allow: 1 });
    if (CallVideo) perm.push({ perm: 3, allow: 1 });
    if (Text) perm.push({ perm: 1, allow: 1 });
    if (Edit) perm.push({ perm: 4, allow: 1 });
    if (deleteP) perm.push({ perm: 5, allow: 1 });
    if (deleteThread) perm.push({ perm: 6, allow: 1 });
    console.log("perm",perm)
    setPermission(perm)
  }, [SeeMessages, CallVoice, CallVideo, Text, Edit, deleteP, deleteThread]);
  useEffect(() => {
    if (!SeeMessages) {
      setCallVoice(false);
      setCallVideo(false);
      setText(false);
      setEdit(false);
      setDelete(false);
      setdeleteThread(false);
    }
  }, [SeeMessages]);
  useEffect(() => {
    if (!Text) {
      setEdit(false);
      setDelete(false);
      setdeleteThread(false);
    }
  }, [Text]);
  useEffect(() => {
    console.log("prmissionList", prmissionList);
    if (prmissionList.length > 0) {
      if (prmissionList?.find((o: any) => o.perm === 1)?.allow === 1)
        setText(true);
      if (prmissionList?.find((o: any) => o.perm === 2)?.allow === 1)
        setCallVoice(true);
      if (prmissionList?.find((o: any) => o.perm === 3)?.allow === 1)
        setCallVideo(true);
      if (prmissionList?.find((o: any) => o.perm === 4)?.allow === 1)
        setEdit(true);
      if (prmissionList?.find((o: any) => o.perm === 5)?.allow === 1)
        setDelete(true);
      if (prmissionList?.find((o: any) => o.perm === 6)?.allow === 1)
        setdeleteThread(true);
      if (prmissionList?.find((o: any) => o.perm === 7)?.allow === 1)
        setSeeMessages(true);
    }
  }, [prmissionList]);
  return (
    <div className="permissionList">
      <div className="permissionCard">
        <div style={{ display: "flex", alignItems: "center" }}>
          <img src="/images/permissionIcon/eye.svg" alt="" />
          Can see {user.full_name}'s Messages
        </div>
        <Switch checked={SeeMessages} onChange={(e) => setSeeMessages(e)} />
      </div>
      <div className="permissionCard">
        <div style={{ display: "flex", alignItems: "center" }}>
          <img src="/images/permissionIcon/call-calling.svg" alt="" />
          Can voice call {user.full_name}
        </div>

        <Switch
          disabled={!SeeMessages}
          checked={CallVoice}
          onChange={(e) => setCallVoice(e)}
        />
      </div>
      <div className="permissionCard">
        <div style={{ display: "flex", alignItems: "center" }}>
          <img src="/images/permissionIcon/video.svg" alt="" />
          Can video call {user.full_name}
        </div>

        <Switch
          disabled={!SeeMessages}
          checked={CallVideo}
          onChange={(e) => setCallVideo(e)}
        />
      </div>
      <div className="permissionCard">
        <div style={{ display: "flex", alignItems: "center" }}>
          <img src="/images/permissionIcon/message-notif.svg" alt="" />
          Can text {user.full_name}
        </div>

        <Switch
          disabled={!SeeMessages}
          checked={Text}
          onChange={(e) => setText(e)}
        />
      </div>
      <div className="permissionCard">
        <div style={{ display: "flex", alignItems: "center" }}>
          <img src="/images/permissionIcon/sms-edit.svg" alt="" />
          Can edit own messages
        </div>

        <Switch
          disabled={!SeeMessages || !Text}
          checked={Edit}
          onChange={(e) => setEdit(e)}
        />
      </div>
      <div className="permissionCard">
        <div style={{ display: "flex", alignItems: "center" }}>
          <img src="/images/permissionIcon/message-remove.svg" alt="" />
          Can delete own messages
        </div>

        <Switch
          disabled={!SeeMessages || !Text}
          checked={deleteP}
          onChange={(e) => setDelete(e)}
        />
      </div>
      <div className="permissionCard">
        <div style={{ display: "flex", alignItems: "center" }}>
          <img src="/images/permissionIcon/trash.svg" alt="" />
          Can delete chat thread
        </div>

        <Switch
          disabled={!SeeMessages || !Text}
          checked={deleteThread}
          onChange={(e) => setdeleteThread(e)}
        />
      </div>
    </div>
  );
};

export default Permissions;
