import { Button, Checkbox, Modal, notification } from "antd";
import React, { useEffect, useState } from "react";
import PermissionsList from "../permissionsList";
import { CloseOutlined } from "@ant-design/icons";
import { UpdatePerm } from "../../../services/groups";

const ManageUsers = ({ Contacts, setPerms, Perms, members, RoomId }: any) => {
  const [CheckList, setCheckList] = useState<any>(
    members ? members?.map((newMembers: any) => newMembers?.id?.toString()) : []
  );
  const [LocPerms, setLocPerms] = useState<any>([]);
  const [Loading, setLoading] = useState<any>(false);

  const HandleSubmit = () => {
    setLoading(true)
    var form_data = new FormData();
    form_data.append("id", RoomId);
    LocPerms.map((per: any, index: any) => {
      form_data.append(`user_ids[${index}][id]`, per.id);
      per.perm.map((perId: any, newIndex: any) => {
        form_data.append(
          `user_ids[${index}][permissions][${newIndex}][perm]`,
          perId
        );
        form_data.append(
          `user_ids[${index}][permissions][${newIndex}][allow]`,
          "1"
        );
      });
    });
    UpdatePerm(form_data).then((res: any) => {
      setLoading(false);
      notification.open({
        type: "success",
        message: "Group members updated successfully",
      });
      Modal.destroyAll();
    }).catch(()=>{
      setLoading(false);
    });
  };

  useEffect(() => {
    let newLocPerms = [];
    if (members && members?.length > 0) {
      newLocPerms = members.map((member: any) => {
        let permsUser = [];
        if (member?.props?.find((x: any) => x.perm === 1).allow === 1)
          permsUser.push(1);
        if (member?.props?.find((x: any) => x.perm === 2).allow === 1)
          permsUser.push(2);
        if (member?.props?.find((x: any) => x.perm === 3).allow === 1)
          permsUser.push(3);
        if (member?.props?.find((x: any) => x.perm === 4).allow === 1)
          permsUser.push(4);
        if (member?.props?.find((x: any) => x.perm === 5).allow === 1)
          permsUser.push(5);
        return { id: member.id, perm: permsUser };
      });
      setLocPerms(newLocPerms);
    }
  }, [members]);
  const OpenPermissionPopUp = (user: any) => {
    Modal.info({
      className: "manageModal",
      title: (
        <div className="modalheader">
          <div className="modalTitle">Select user role</div>
        </div>
      ),
      width: "40%",
      content: (
        <PermissionsList
          Permissions={
            members
              ? LocPerms?.find(
                  (x: any) => x?.id.toString() === user?.contact.id.toString()
                )?.perm
              : []
          }
          setRoles={(roles: any) => {
            console.log("roles", roles);
            setPerms(roles);
            if (roles.length > 0) {
              let newArr = LocPerms;
              if (
                newArr.filter((e: any) => e.id === user?.contact.id).length > 0
              )
                newArr.map((obj: any) => {
                  if (obj.id === user?.contact.id) obj.perm = roles;
                });
              else newArr.push({ id: user?.contact.id, perm: roles });
              setLocPerms([...newArr]);
              setPerms([...newArr]);
            }
          }}
        />
      ),
      centered: false,
      onOk() {},
      okText: "Confirm",
      closeIcon: (
        <>
          <CloseOutlined color="#fff" />
        </>
      ),
      maskClosable: true,
      closable: true,
      autoFocusButton: null,
    });
  };
  return (
    <>
      <Checkbox.Group
        onChange={(e: any) => setCheckList(e)}
        defaultValue={
          members
            ? members?.map((newMembers: any) => newMembers?.id?.toString())
            : []
        }
      >
        <div className="chatUsers">
          {Contacts?.data?.json.map((user: any) => (
            <div className={"userCard"}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <div className="cardImgCon">
                  <div className="imgPlaceHolder">
                    <img src={user?.contact?.profile_image?.url} alt="" />
                  </div>
                </div>
                <div className="cardText">
                  <div className="cardTitle">{user?.contact?.full_name}</div>
                </div>
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                {CheckList.includes(user?.contact?.id.toString()) && (
                  <Button
                    onClick={(e) => OpenPermissionPopUp(user)}
                    className="editButton"
                    // loading={CanEdit === user?.contact?.username}
                  >
                    {(!LocPerms.find((x: any) => x.id === user?.contact?.id)
                      ?.perm ||
                      LocPerms.find((x: any) => x.id === user?.contact?.id)
                        ?.perm.length === 0) &&
                      "Choose roles"}
                    {LocPerms.find(
                      (x: any) => x.id === user?.contact?.id
                    )?.perm.includes(1) && (
                      <>
                        <img src="/images/message-tick.png" />
                        Read-Only
                      </>
                    )}
                    {LocPerms.find(
                      (x: any) => x.id === user?.contact?.id
                    )?.perm.includes(3) && (
                      <>
                        <img src="/images/ghost.png" />
                        Hidden
                      </>
                    )}
                    {LocPerms.find(
                      (x: any) => x.id === user?.contact?.id
                    )?.perm.includes(2) && (
                      <>
                        <img src="/images/messages.png" />
                        Read-Write
                      </>
                    )}
                  </Button>
                )}
                <Checkbox value={user?.contact?.id.toString()} />
              </div>
            </div>
          ))}
        </div>
      </Checkbox.Group>
      {RoomId && <Button loading={Loading} className="ConfirmButton" onClick={() => HandleSubmit()}>Confirm</Button>}
    </>
  );
};

export default ManageUsers;
